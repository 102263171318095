import React,{useContext} from 'react'
import {Route,Redirect} from 'react-router-dom'
import {UserContext} from './UserContext'
import DashboardNav from './DashboardNav'
import Footer from './Footer'


export default function ProtectedRoute({component:Component,...rest}) {
    const {isAuth}=useContext(UserContext)
    const {setUsertoken}=useContext(UserContext)
    const {setIsAuth}=useContext(UserContext)
    const {setCustomername}=useContext(UserContext)




    return (
        <Route {...rest} render={props=>{
        
            if (isAuth || (localStorage.getItem("Auth")==1)){

                setIsAuth(true)
                setUsertoken(localStorage.getItem("userToken"))
                setCustomername(localStorage.getItem("Customername"))  

                return (
                    <>
                    <DashboardNav/>    
                    <Component />
                    <Footer/>
                    </>
                )
            }else{

                 return <Redirect to={{pathname:"/login",state:{from: props.location}}}/>

                
            }
        }}/>
    )
}
