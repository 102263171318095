import React from 'react'
import {Navbar,Nav,NavDropdown,Alert} from 'react-bootstrap'
import smtrxLogo from '../smtrxLogo.png'

export default function Footer() {

    const style={
        position: "fixed",
        left: "0",
        bottom: "0",
        width: "100%",
        backgroundColor: "#f8f9fa",
        color: "black",
        textAlign: "center"    
    }

    return (
        <>
            <br/><br/><br/>  

            <Navbar bg="secondary" fixed="bottom" >
            <table width="100%">
                <tr>
                    <td width="110" align="center">
                    <img
                    src={smtrxLogo} 
                    width="100"
                    
                    className="d-inline-block align-top"
                    alt="Seametrix"
                />
                    </td>
                    <td align="center">
                    <font color="#f0f0f0">
                    © 2021 Seametrix - All rights reserved.
                    </font>
                    </td>
                    <td width="110" align="center"></td>
                </tr>
            </table>
            </Navbar>   
        </>
    )
}
