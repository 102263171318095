import React ,{useContext,useState,useEffect} from 'react'
import {Nav,Alert,Card,Button,Form,Table} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import BuyNav from './BuyNav'

import {UserContext} from './UserContext'
import BuyStep5PP from './BuyStep5PP';
import BuyStep5BT from './BuyStep5BT'
import BuyStep5CC from './BuyStep5CC'


export default function BuyStep5() {
  const {items,setItems,setCurrentstep,order,setOrder,confirmmydata,setConfirmmydata,customer,setCustomer}=useContext(OrderContext)
  const {usertoken}=useContext(UserContext)

   


    const btnStyle={
        width: "150px"
    }    
    
    return (
        <>
        <Card className="text-center">
        <Card.Header>
          <BuyNav/>
        </Card.Header>
        <Card.Body>
        <Card.Title>Checkout</Card.Title>
          
          {order.paymentMethodID==1 && <BuyStep5CC/>}
          {order.paymentMethodID==2 && <BuyStep5PP/>}
          {order.paymentMethodID==3 && <BuyStep5BT/>}

      </Card.Body>
      <Card.Footer className="text-muted">
            <Button onClick={()=>setCurrentstep(4)}  variant="secondary" style={btnStyle}>Previous</Button>

        </Card.Footer>
    </Card>
    </>




)
}
