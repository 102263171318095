import React ,{useContext,useState,useEffect,useRef} from 'react'
import {Nav,Alert,Card,Button,Table,Modal,Form} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import {UserContext} from './UserContext'
import BuyNav from './BuyNav'

import './Buy.css'



export default function BuyStep1() {
    
    const {items,setItems,setCurrentstep,order,setOrder,currentstep,renewItems}=useContext(OrderContext)
    
    const couponNumber=useRef()
    const [isLoading,setIsLoading]=useState(true)
    
    const [total,setTotal]=useState(0)
    const [discount,setDiscount]=useState(0)
    const [gtotal,setGtotal]=useState(0)
    const [coupon,setCoupon]=useState('')

    //const [products,setProducts]=useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }



    useEffect(() => {
        
        if (items.length==0){
            const formData=new FormData()
            const itemList=[...items]
            formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
            axios.post(process.env.REACT_APP_API_SERVER+'products',formData,{}) 
            .then(res=>{
                res.data.forEach(i=>{
                    var item={
                        "id":i.id,
                        "code":i.code,
                        "title":i.title,
                        "price": parseFloat(i.sale_value).toFixed(2),
                        "licences":0,
                        "years":1,
                        "subtotal":0,
                        "description":i.description,
                        "renew_id":0
                    }
                    itemList.push(item)
                })
            
                var newOrder={}
                newOrder.total=0
                newOrder.gtotal=0
                newOrder.discount=0
                newOrder.coupon=''
                setOrder(newOrder)

                setItems(itemList)
                setIsLoading(false)
            }).catch(err=>{})
        }else{

            setTotal(order.total)
            setDiscount(order.discount)
            setGtotal(order.gtotal)
            setIsLoading(false)
        }
        
    },[])

    const getDiscount=(e)=>{
        e.preventDefault()

        document.getElementById("discLoading").style.display="block"
        const formData=new FormData()
        formData.append('couponNumber',couponNumber.current.value)
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        axios.post(process.env.REACT_APP_API_SERVER+'discountCoupons',formData,{}) 
        .then(res=>{
            if (res.data>0){
                document.getElementById("discLoading").style.display="none"
                document.getElementById("discOK").style.display="block"
                setTimeout(function() {document.getElementById("discOK").style.display="none"}, 1500);
                setCoupon(couponNumber.current.value)
            }else{
                document.getElementById("discLoading").style.display="none"
                document.getElementById("discNOTOK").style.display="block"
                setTimeout(function() {document.getElementById("discNOTOK").style.display="none"}, 1500);
                setCoupon('')  
            }
            setDiscount(res.data) 
            couponNumber.current.value=''
            const gTotalCost=parseFloat(total)*(100-parseFloat(res.data))/100
            setGtotal(gTotalCost)           
        }).catch(err=>{
            setDiscount(0)
            couponNumber.current.value=''
        })

    }

    const chLicences=(e,index)=>{
        const l=e.target.value
        const itemList=[...items]
        const sbt=l*itemList[index].price*itemList[index].years
        itemList[index].licences=l
        itemList[index].subtotal=sbt.toFixed(2)

        var totalCost=0
        itemList.forEach(item=>{
            totalCost=totalCost+parseFloat(item.subtotal)
        })

        const gTotalCost=parseFloat(totalCost)*(100-parseFloat(discount))/100
        setGtotal(gTotalCost)

        setTotal(totalCost.toFixed(2))
        setItems(itemList)

    }


    const chYears=(e,index)=>{
        var l=e.target.value
        const itemList=[...items]
        
        if (l>2){
            l=2
        }
        const sbt=l*itemList[index].price*itemList[index].licences
        itemList[index].years=l
        itemList[index].subtotal=sbt.toFixed(2)
        var totalCost=0

        var totalCost=0
        itemList.forEach(item=>{
            totalCost=totalCost+parseFloat(item.subtotal)
        })

        const gTotalCost=parseFloat(totalCost)*(100-parseFloat(discount))/100
        setGtotal(gTotalCost)

        setTotal(totalCost.toFixed(2))
        setItems(itemList)
    }

    const goStep2=()=>{
        
        var newOrder={}

        if (order.customertype){
            newOrder=order
        }
        
        newOrder.total=total
        newOrder.gtotal=gtotal
        newOrder.discount=discount
        newOrder.items=items.filter(item=>item.licences>0)
        newOrder.otoken='0'
        newOrder.coupon=coupon
        newOrder.type='NEW LICENCE(S)'
        setOrder(newOrder)


        setCurrentstep(2)
    }


      const itemList=items.map((item,index)=>{

        return(
            <>
            <tr key={item.id} bgColor={item.subtotal==0 ? "#f0f0f0" : "#d4f2c1"} >
                <td><img key={'imgc'+item.id} src={require('../'+item.code+'.png').default}/></td>
                <td>{item.title}</td>
                <td>{item.price}</td>
                <td align="center">
                    <Form.Control key={'l'+item.id} type="number" min="0" onChange={(e)=>chLicences(e,index)} value={item.licences} style={{width:"100px"}}/>
                </td>
                <td align="center">
                <Form.Control type="number" min="1" max="2" onChange={(e)=>chYears(e,index)} value={item.years}  style={{width:"100px"}}/>
                </td>
                <td>{item.subtotal}</td>
            </tr>
            <tr><td height="10" colSpan="6"></td></tr>
            </>
        )
      })    


    const btnStyle={
        width: "150px"
    }    
    
    if (isLoading){
        return(
            <Alert  variant="warning">
                Loading...Please Wait
            </Alert>
        )
    }else{
         return (
            <>
            <Card className="text-center">

            <Card.Header>
            <BuyNav/>
            
            </Card.Header>
            <Card.Body >
            <Table hover className="table table-hover" cellSpacing="2">
                <thead>
                    <tr>
                    
                    <th colSpan="2"><font color="#343a40">Product</font></th>
                    <th><font color="#343a40">Price &euro;</font></th>
                    <th><font color="#343a40">Number of Licences</font></th>
                    <th><font color="#343a40">Period (In years)</font></th>
                    <th><font color="#343a40">Subtotal &euro;</font></th>
                    </tr>
                </thead>
                <tbody>
                    {itemList}



                </tbody>
                </Table>

                <table width="100%">

                    <tbody>
                    <tr>
                        <td width="50%" valign="top">
                            <Card style={{ width: '30rem' }}>
                            
                            <Card.Body>
                                <Card.Title><font color="#82a8d2">Have a coupon code?</font></Card.Title>

                                <div id="discLoading" style={{display:"none"}}>
                                <br/>
                                <Alert  variant="info">
                                Please Wait. Applying coupon....
                                </Alert>
                                </div>

                                <div id="discOK" style={{display:"none"}}>
                                <br/>
                                <Alert  variant="success">
                                Discount applied succesfully!
                                </Alert>
                                </div>

                                <div id="discNOTOK" style={{display:"none"}}>
                                <br/>
                                <Alert  variant="danger">
                                Invalid coupon number.
                                </Alert>
                                </div>  

                                <Form onSubmit={getDiscount}>
                                <Form.Control required ref={couponNumber} type="text" placeholder="Enter it here to apply discount"></Form.Control>
                                <br/>
                                <Button type="submit" variant="dark" >Apply Coupon</Button>
                                </Form>
                            </Card.Body>
                            </Card>
                        </td>
                        <td width="50%" align="right" valign="top">

                        <Card  style={{ width: '30rem',textAlign: "center" }}>
                        <Card.Header ><b><font color="red">Total: {parseFloat(gtotal).toFixed(2)} &euro;</font></b></Card.Header>
                        <Card.Body>

                            <Card.Text>
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td align="left">
                                    <b>Licence(s) total cost &euro;:</b>
                                    </td>
                                    <td align="right">
                                    {parseFloat(total).toFixed(2)} 
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                    <b>Coupon Discount ({discount}%) &euro;:</b>
                                    </td>
                                    <td align="right">
                                    {(parseFloat(total-gtotal)*(-1)).toFixed(2)}  
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                             
                            <br/>
                            
                            </Card.Text>

                        </Card.Body>
                        </Card>


                        </td>
                    </tr>                        
                    </tbody>

                </table>



            </Card.Body>
            <Card.Footer className="text-muted">
                <Button variant="secondary" style={btnStyle} disabled={true}>Previous</Button>
                &nbsp;
                <Button variant="primary" onClick={goStep2} style={btnStyle} disabled={!parseFloat(total)>0}>Next</Button>
            </Card.Footer>
            </Card>

            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>

            </>
        )
    }
}
