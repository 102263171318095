import React ,{useContext,useState,useEffect} from 'react'
import {Nav,Alert,Card,Button,Form,Table,Modal,Overlay} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import {UserContext} from './UserContext'
import {FaCreditCard} from 'react-icons/fa'
import {Redirect,useHistory} from 'react-router-dom'



export default function () {
    const {items,setItems,setCurrentstep,order,setOrder,confirmmydata,setConfirmmydata,customer,setCustomer,blockscreen,setBlockscreen}=useContext(OrderContext)
    const {usertoken}=useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const history=useHistory()
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [errormsg, setErrormsg] = useState('');




    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);

        let ppWin=window.open(process.env.REACT_APP_API_SERVER+'checkOutCC?uyhggd6736efd873='+order.otoken,'win','top:50,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height:600')
        var popupTick = setInterval(function() {
            if (ppWin.closed) {
              clearInterval(popupTick);
              windowUnload()
            }
          }, 500);        
        
        setIsLoading(true)
    }



    const windowUnload=()=>{

        setError(false)
        const formData=new FormData()
        formData.append('otoken',order.otoken)
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        axios.post(process.env.REACT_APP_API_SERVER+'order',formData,{}) 
        .then(res=>{
                if (res.data==1){
                    history.push('/dashboard/licencedetails')
                    setError(false)
                }else{
                    setError(true)
                    
                }
        }).catch(err=>{

        })         
        
        setInterval(() => {
            setError(false)
        }, 3000);
        
        setShow(false)
        setIsLoading(false)

        
    
    }


    return (
        <div>
            <hr/><h4>Amount: {order.finalCost}&euro;</h4><hr/>
            <Button onClick={handleShow} variant={'primary'} style={{width:"360px",height:"45px"}}><FaCreditCard/>&nbsp;Proceed to Payment</Button>
            <br/><br/><br/><br/>

            {show &&             
                <Modal show={show} style={{width:'100%',height:'100%'}}></Modal>
            }
            {isLoading && <><hr/><Alert  variant="warning">Processing Your Order....Please Wait</Alert><hr/></>}        
            {error && <><hr/><Alert  variant="danger">Transaction canceled.</Alert><hr/></>}  
        </div>
    )
}
