import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {Nav,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import { FaListAlt,FaDownload,FaCreditCard,FaFileInvoice,FaInfoCircle, FaChessQueen } from 'react-icons/fa'
import {Redirect,useHistory} from 'react-router-dom'
import LecencesActivePreviousDocuments from './LecencesActivePreviousDocuments'

export default function LicencesActive(props) {
    const {customername}=useContext(UserContext)
    const {isAuth,usertoken}=useContext(UserContext)
    const [licences,setLicences]=useState([])
    const [numOfLicences,setNumOfLicences]=useState(0)
    const [licence,setLicence]=useState(0)
    const [changeCreds,setChangeCreds]=useState(false)
    const [licenceDets,setLicenceDets]=useState([])
    const [licenceCreds,setLicenceCreds]=useState({SUN:"",SPWD:""})
    const [licenceNewCreds,setLicenceNewCreds]=useState({SUN:"",SPWD:""})
    const [isLoading,setIsLoading]=useState(true)  
    const [renewItems,setRenewItems]=useState([])
    const [renewBtnDsb,setRenewBtnDsb]=useState(true)
    const [checkDsb,setCheckDsb]=useState(false)
    const history=useHistory()  

    useEffect(()=>{

        setLicences(props.licences)
        setNumOfLicences(props.licences.length)
        setIsLoading(false)
        //console.log(props.licences)
    },[])     


    const licenceDetails=(l)=>{
        setChangeCredsF(false)
        setLicence(l)
        setLicenceDets(licences.filter(item=>item.OrderItemID==l)[0])
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('OrderItemID',l)  
        axios.post(process.env.REACT_APP_API_SERVER+'licencecreds',formData,{})
        .then(res=>{  
            setLicenceCreds(res.data)
        })        
    }

    const getRenewItem=(e,OrderItemID)=>{
        var itemList=[]
        setRenewBtnDsb(true)
        setCheckDsb(true)
        if (e.target.checked){
            const formData=new FormData()
            formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
            formData.append('ItemCode',e.target.value)
            axios.post(process.env.REACT_APP_API_SERVER+'products',formData,{}) 
            .then(res=>{
                itemList=[...renewItems]
                res.data.forEach(i=>{
                    var item={
                        "id":i.id,
                        "code":i.code,
                        "title":"RENEW LICENCE FOR "+i.title,
                        "price": parseFloat(i.sale_value).toFixed(2),
                        "licences":1,
                        "years":0,
                        "subtotal":0,
                        "description":i.description,
                        "renew_id":OrderItemID
                    }
                    itemList.push(item)
                    setRenewItems(itemList)
                    setRenewBtnDsb(false)
                    setCheckDsb(false)
                    //console.log(itemList)
                })
            })
        }else{
            itemList=renewItems.filter(item=>item.renew_id!=OrderItemID)
            //console.log(itemList)
            setRenewItems(itemList)
            setCheckDsb(false)
            if (itemList.length>0){
                setRenewBtnDsb(false)
                
            }
        }

        
    }
  

    const chCreds=(e,i)=>{
        const val=e.target.value
        if (i==1){
            setLicenceNewCreds({...licenceNewCreds,SUN:val}) 
        }else{
            setLicenceNewCreds({...licenceNewCreds,SPWD:val}) 
        }
    }

    const setChangeCredsF=(s)=>{
        setChangeCreds(s)
        setLicenceNewCreds({...licenceNewCreds,SUN:'',SPWD:''}) 
    }

    const showPendingDets=(otoken)=>{
        history.push('/dashboard/order/'+otoken)
    }

    const cancelOrder=(oid,ok)=>{

        const items=licences.filter(item=>item.Timestamp==ok)
        const restItems=licences.filter(item=>item.Timestamp!=ok)
        if (window.confirm('CANCEL the order: #'+ok+' ('+items.length+' licences)?')){
            
            const formData=new FormData()
            formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
            formData.append('XOrderID',oid)
            axios.post(process.env.REACT_APP_API_SERVER+'cancelorder',formData,{}) 
            .then(res=>{
                if (res.data==0){
                    setLicences(restItems)
                    props.setNumOfPendingLicences(props.numOfPendingLicences-items.length)    
                } 
            })
            

        }
        
    }

    const saveNewCreds=(e,OrderItemID,OrderID)=>{
        e.preventDefault()

        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('OrderItemID',OrderItemID)
        formData.append('OrderID',OrderID)
        formData.append('SUN',licenceNewCreds.SUN)
        formData.append('SPWD',licenceNewCreds.SPWD)
        axios.post(process.env.REACT_APP_API_SERVER+'changesmtxcreds',formData,{}) 
        .then(res=>{ 
            if (res.data.err==0){
                setLicenceCreds(licenceNewCreds)
                setLicenceNewCreds({...licenceNewCreds,SUN:'',SPWD:''}) 
                setChangeCreds(false)
            }else{
                alert(res.data.msg)
            } 
        }) 
    }


var ts='';
var istyle={}
const itemList=licences.map((item,index)=>{
    if (index==0){
        ts=item.Timestamp
        istyle={color:'green'}
    }else{
        if (item.Timestamp!=ts){
            ts=item.Timestamp
            istyle=istyle.color=='green' ? {color:'blue'} : {color:'green'}
        }
    }


return(
    <>
    <tr key={item.id}  style={licence==item.OrderItemID ? {backgroundColor: '#e9efd9'}:null}>
        <td><img src={require('../'+item.ItemCode+'.png').default}/></td>
        <td style={istyle}>{item.Timestamp}</td>
        <td>{item.ItemTitle}</td>
        
        <td>{item.ADate}</td>
        <td>{item.EDate}</td>
        <td>
            <span style={item.DaysLeft>30 ? {color:"black"} : {color:"red"}}>
                {item.DaysLeft}
            </span>
        </td>
        <td>{item.XSUN}</td>
        <td>
        {(licence!=item.OrderItemID && props.details==1) &&
        <Button variant="dark" onClick={()=>{licenceDetails(item.OrderItemID)}} style={{width:"180px"}}>Credentials & Details</Button>
        }

        {(licence==item.OrderItemID && props.details==1) &&
        <Button variant="secondary" onClick={()=>{licenceDetails(0)}} style={{width:"180px"}}>Hide Details</Button>
        }

        {/* {props.details==2 &&
        <Button variant="danger" onClick={()=>cancelOrder(item.XOrderID,item.Timestamp)} style={{width:"180px"}}>Cancel Order</Button>
        } */}

        {props.details==2 &&
        <Button variant="secondary" onClick={()=>showPendingDets(item.XOrderID)} style={{width:"180px"}}>Details</Button>
        }

        {(props.renew==1 && item.ItemCode!=1) &&
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check disabled={checkDsb} type="checkbox" label="Renew" value={item.ItemCode} onChange={(e)=>{getRenewItem(e,item.OrderItemID)}}/>
            </Form.Group>            
        }
        </td>
    </tr>
    {licence==item.OrderItemID &&
    <tr style={licence==item.OrderItemID ? {backgroundColor: '#e9efd9'}:null}>
        <td colSpan="8" align="left">
            
                {licenceDets && 
                    <>
                    <table width="100%">
                        <tr>
                            <td valign="top" width="50%">
                                <table width="100%">
                                    <tr><td colSpan="2"><b>{licenceDets.ItemTitle} Credentials</b></td></tr>
                                    {!changeCreds && 
                                    <>
                                    <tr>
                                        <td>
                                            Seametrix User Name:
                                        </td>
                                        <td>
                                            {licenceCreds.SUN.trim()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Seametrix Password:
                                        </td>
                                        <td>
                                            {licenceCreds.SPWD.trim()}
                                        </td>
                                    </tr>
                                    
                                    </>
                                    }
                                    <tr>
                                        <td align="left" colSpan="2">
                                            {!changeCreds && 
                                                <Button 
                                                    variant="info"
                                                    onClick={()=>{setChangeCredsF(true)}}>Change Credentials</Button>
                                            }
                                            {changeCreds &&
                                                <Form onSubmit={(e)=>{saveNewCreds(e,item.OrderItemID,item.OrderID)}}>
                                                <Form.Group controlId="SUN">
                                                    <Form.Label>User Name</Form.Label>
                                                    <Form.Control minLength="6" value={licenceNewCreds.SUN} onChange={(e)=>{chCreds(e,1)}} required type="text" placeholder="Seametrix User Name" />
                                                </Form.Group>
                                                <Form.Group controlId="SPWD">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control minLength="6" value={licenceNewCreds.SPWD} onChange={(e)=>{chCreds(e,2)}} required type="text" placeholder="Seametrix Password" />
                                                </Form.Group>

                                                <Button 
                                                    variant="danger"
                                                    onClick={()=>{setChangeCredsF(false)}}>Cancel
                                                </Button>
                                                &nbsp;
                                                <Button variant="primary" type="submit">
                                                    Save Credentials
                                                </Button>

                                                </Form>
                                            }
                                            
                                        </td>
                                    </tr>                                
                                </table>
                            </td>
                            <td valign="top" width="50%">
                            {item.ItemCode!=1 &&
                                                                  
                                <table width="100%">
                                    <tr><td><b>Current Invoice/Receipt</b></td></tr>
                                    <tr><td>
                                    <a href={licenceDets.InvoiceLink} target="_blank">Order #{item.Timestamp} Invoice or Receipt</a>
                                    
                                    {item.ReNewedOrderID>0 &&
                                    <>
                                    &nbsp;<i>(Renewal)</i>
                                    <LecencesActivePreviousDocuments ReNewedOrderID={item.ReNewedOrderID}/>
                                    </>
                                    }           

                                    </td></tr>
                                </table>
                            }  
                            </td>

                        </tr>
                    </table>
                    <br/><br/>
                    </>
                }
                

        </td>
    </tr>
    }
    </>
)
}) 



    
    

        return (
        <>
        <br/>
        <Table striped className="table" cellSpacing="2">
            <thead>
            <tr>
                <th></th>
                <th>Order Key</th>
                <th ><font color="#343a40">Product</font></th>
                <th>Activation Date</th>
                <th>Expiration Date</th>
                <th>Days Left</th>
                <th>Username</th>
                <th>
                {props.renew==1 &&
                    <Button disabled={renewBtnDsb} variant="success" onClick={()=>{history.push({
                        pathname:"/dashboard/renew",
                        state: { renewItems: renewItems }

                    })}}>RENEW SELECTED ({renewItems.length})</Button>
                }

                </th>
            </tr>
            </thead>
            <tbody>
                {itemList}
            </tbody>
        </Table>
        </>
        )

}
