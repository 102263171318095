import React ,{useContext,useState,useEffect,useRef} from 'react'
import {Nav,Alert,Card,Button,Form,Table} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import {UserContext} from './UserContext'

export default function BuyStep2DetailsForm() {
    
    const {confirmmydata,setConfirmmydata,customer,setCustomer,order,setOrder}=useContext(OrderContext)

    const [countries,setCountries]=useState([])
    const [isLoading,setIsLoading]=useState(true)  
    const [vatflag,setVatflag]=useState(0)
    const [vat,setVat]=useState(0)
    const [viescode,setViescode]=useState('')
    const [eu,setEu]=useState(0)   
    const [vatIsValid,setVatIsValid]=useState(true)
    const [vatresponse,setVatresponse]=useState('')
    const [vatresponseclr,setVatresponseclr]=useState('warning')
    const [formIsValid,setFormIsValid]=useState(false)
    

    const VATRef=useRef()

    useEffect(()=>{
        fetch(process.env.REACT_APP_API_SERVER+'countries')
        .then((res)=>res.json())
        .then((data)=>{           
            setCountries(data)

            setViescode(customer.VIESCode)
            setEu(customer.EU)   
            setConfirmmydata(false)            
            
            var cusCoun=data.filter(item=>item.CountryCode===customer.Country)[0]
            
            var vVPC=0
            if (customer.CustomerType==1){
                if (cusCoun.EU==1){
                    if (cusCoun.VATFLAG==1){
                        vVPC=cusCoun.VAT
                    }
                }
                
            }else{
                if (cusCoun.EU==1){
                    vVPC=cusCoun.VAT
                } 
            }
          
            
            setOrder({...order,'vatValuePerCent': vVPC})
            setIsLoading(false)
            
        })
        

        //setCustomer({...customer,CustomerType:'1'})

    },[customer])

    const countriesList=countries.map(item=>{

        return(
            <option key={item.CountryCode} value={item.CountryCode}>{item.Country}</option>
        )
    })



    const selCountry=(e)=>{
        
        changeFldValue(e,'Country')
        if (e.target.value.length>0){
            if (customer.CustomerType==1){
                VATRef.current.value=''
            }
            const counCode=e.target.value
            const selectedCountry=countries.filter(item=>item.CountryCode==counCode)[0]
    
            setEu(selectedCountry.EU)
            setVatflag(selectedCountry.VATFLAG)
            setVat(selectedCountry.VAT)
            setViescode(selectedCountry.VIESCode)
            setVatresponse('')
            
            var vatValuePerCent=0
            
            if (customer.CustomerType==1){
                if (selectedCountry.EU==1){
                    if (selectedCountry.VATFLAG==1){
                        vatValuePerCent=selectedCountry.VAT
                    }
                }
            }else{
                if (selectedCountry.EU==1){
                    vatValuePerCent=selectedCountry.VAT
                }                
            }

        }else{
            vatValuePerCent=0
        }

        

        setOrder({...order,'vatValuePerCent':vatValuePerCent})

    }

    const chVatnum=(e)=>{
        setVatresponse('Please Wait...Checking Registration/Identification No.')
        setVatresponseclr('info')
        const vat=e.target.value
        changeFldValue(e,'VAT')
        if (eu==1){
            if (/^\w+$/.test(vat)){
                const formData=new FormData()
                formData.append('Country',viescode)
                formData.append('VAT',vat)

                formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
                axios.post(process.env.REACT_APP_API_SERVER+'checkVat',formData,{})  
                .then(res=>{
                    if (res.data.valid_vat){
                        setVatresponseclr('success')
                    }else{
                        setVatresponseclr('warning')
                    }
                    
                    setVatresponse(res.data.response)
                    setVatIsValid(res.data.valid_vat)

                }) 
            }else{ 
                //setCustomer({...customer,VAT:''})
                setVatresponseclr('warning')
                setVatresponse('-INVALID VAT NUMBER-')
                setVatIsValid(false)                
            }
        }else{
            if (vat.length<5){
                setVatresponseclr('warning')
                setVatresponse('Please enter a valid Registration/Identification No') 
                setVatIsValid(false)
            }else{
                //setVatresponse('') 
                setVatIsValid(true)
            }
        }
    }

    const changeFldValue=(e,key)=>{
        
       if (key=='Country'){
            const counCode=e.target.value
            const selectedCountry=countries.filter(item=>item.CountryCode==counCode)[0]
            setCustomer({...customer,Country:e.target.value,VAT:'',VIESCode:selectedCountry.VIESCode})
            
            
       }else{
            setCustomer({...customer,[key]:e.target.value})
       }

       validateForm()
      
       
    }

    const validateForm=()=>{
        setFormIsValid(true)

        if (customer.CustomerType==1){
            for (var i=1;i<10;++i){
                if (document.getElementById("f"+i).value.length==0){
                    setFormIsValid(false)
                    setConfirmmydata(false)
                }
            }
        }else{

            for (var i=1;i<8;++i){
                if (document.getElementById("f"+i).value.length==0){
                    setFormIsValid(false)
                    setConfirmmydata(false)
                }
            }            
        }



    }

    const confMyData=(e)=>{
        validateForm()

        if (customer.CustomerType==1){
            if (e.target.checked && formIsValid && vatIsValid){
                setConfirmmydata(true)
            }else{
                setConfirmmydata(false)
            }            
        }else{
            if (e.target.checked && formIsValid){
                setConfirmmydata(true)
            }else{
                setConfirmmydata(false)
            }
        }

            

    }


    if (isLoading){
        return(
            <>
            <div className="container">

            <br/>
            <Alert  variant="warning">
                Loading...Please Wait
            </Alert>
            </div>
            </>
    
        )
    }else{
    return (
        <>
        
        {customer.CustomerType==1 &&
            <Alert variant="success">
            Purchase as a <b>Company</b>
            </Alert>       
        }

        {customer.CustomerType==2  && 
            <Alert variant="success">
            Purchase as a <b>Individual</b>
            </Alert>
        }


        
            <Table hover striped>
                <tr><td colSpan="2" align="center">
                    <font color="#00acc1">
                    All information should be entered in English
                    </font>
                </td></tr>
                {customer.CustomerType==1 &&
                <tr>

                    <td>
                        Company Name (*)
                    </td>

                    <td>
                        <Form.Control
                            id="f8" 
                            style={{borderColor:customer.CompanyName.length>0 ? '#00acc1': '#f36257'}} 
                            type="text" value={customer.CompanyName}  
                            onChange={(e)=>changeFldValue(e,'CompanyName')}
                        />
                    </td>

                </tr>
                }
                <tr>
                    <td>
                        Email (*)
                    </td>
                    <td>
                        <Form.Control readOnly type="email" value={customer.Email} />
                    </td>
                </tr>

                <tr>
                    <td>
                        Country (*)
                    </td>
                    <td>
                        <Form.Control as="select" 
                            id="f1"
                            onChange={selCountry} value={customer.Country}
                            style={{borderColor:customer.Country.length>0 ? '#00acc1': '#f36257'}}
                        >
                            <option value="">[* Choose Country]</option>
                            {countriesList}
                        </Form.Control>

                    </td>
                </tr>
                {customer.CustomerType==1 &&
                <tr>
                    <td>
                    Registration/Identification No (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text"  
                            id="f9" 
                            
                            onChange={chVatnum} 
                            ref={VATRef} 
                            value={customer.VAT}
                            style={{borderColor:(customer.VAT.length>0 && vatIsValid) ? '#00acc1': '#f36257'}}
                            />
                        <font size="2">
                        Input only if you purchase as a company. DO NOT USE country's prefix code for VAT numbers, e.g. GR.
                        </font>                         
                    </td>
                </tr>
                }
                {(vatresponse.length>0 && customer.CustomerType==1) &&
                <tr>
                    <td colSpan="2">
                    <><br/><Alert variant={vatresponseclr}>
                        <div dangerouslySetInnerHTML={{ __html:vatresponse}}/>
                    </Alert></>
                    </td>
                </tr>
                }


                <tr>
                    <td>
                        First Name (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            id="f2" 
                            value={customer.CustomerName} onChange={(e)=>changeFldValue(e,'CustomerName')}
                            style={{borderColor:customer.CustomerName.length>0 ? '#00acc1': '#f36257'}}
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                        Last Name (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            id="f3" 
                            value={customer.CustomerLastName} 
                            onChange={(e)=>changeFldValue(e,'CustomerLastName')}
                            style={{borderColor:customer.CustomerLastName.length>0 ? '#00acc1': '#f36257'}}
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                    Address (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            id="f4" 
                            value={customer.Address} 
                            onChange={(e)=>changeFldValue(e,'Address')}
                            style={{borderColor:customer.Address.length>0 ? '#00acc1': '#f36257'}}
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                    Address 2
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            placeholder="Any additional details, as apartment, building etc..." 
                            value={customer.AddressExtraInfo} 
                            onChange={(e)=>changeFldValue(e,'AddressExtraInfo')}

                            />
                    </td>
                </tr>
                <tr>
                    <td>
                    City/Town (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            id="f5" 
                            value={customer.City} 
                            onChange={(e)=>changeFldValue(e,'City')}
                            style={{borderColor:customer.City.length>0 ? '#00acc1': '#f36257'}}
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                    Zip/Postal Code (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            id="f6" 
                            value={customer.Zip} 
                            onChange={(e)=>changeFldValue(e,'Zip')}
                            style={{borderColor:customer.Zip.length>0 ? '#00acc1': '#f36257'}}    
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                    State/Province (*)
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            id="f7" 
                            value={customer.State} 
                            onChange={(e)=>changeFldValue(e,'State')}
                            style={{borderColor:customer.State.length>0 ? '#00acc1': '#f36257'}}
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                    Telephone
                    </td>
                    <td>
                        <Form.Control 
                            type="text" 
                            
                            value={customer.Telephone} onChange={(e)=>changeFldValue(e,'Telephone')}
                            
                            />
                    </td>
                </tr>

                <tr>
                    <td>
                    
                    </td>
                    <td align="left">
                        <Form.Check style={{color:"#00acc1",fontWeight:"bold"}} checked={confirmmydata} onChange={confMyData} type="checkbox" label="I confirm that all the above information is true and correct"/>
                        
                    </td>
                </tr>

            </Table>
       
        </>
    )}
}
