import React ,{useContext,useState,useEffect} from 'react'
import {Nav,Alert,Card,Button,Form,Table} from "react-bootstrap"
import {OrderContext} from './OrderContext'
import BuyNav from './BuyNav'
import BuyStep2DetailsForm from './BuyStep2DetailsForm'
import {UserContext} from './UserContext'

export default function BuyStep2() {
  
  const {items,setItems,setCurrentstep,order,setOrder,confirmmydata,setConfirmmydata,customer,setCustomer}=useContext(OrderContext)
  const {usertoken}=useContext(UserContext)

 


    useEffect(()=>{

      
    },[])

  

    const setOrderCustomerType=(ct)=>{
        setCustomer({...customer,'CustomerType':ct})
    }

    const sbFrm=(e)=>{
      e.preventDefault()
      setCurrentstep(3)
    }

    const btnStyle={
        width: "150px"
    }    

    const changeCustomerType=(ct)=>{
      setConfirmmydata(false)
      setOrderCustomerType(ct)
    }

    return (
        <>
          <Card className="text-center">
            <Card.Header>
              <BuyNav/>
            </Card.Header>
            <Card.Body>
            <Card.Title>Billing Details</Card.Title>
              
              <Button variant={customer.CustomerType==1 ? "success" :"secondary"} onClick={()=>changeCustomerType(1)}>Purchase as Company</Button>
              &nbsp;&nbsp;
              <Button variant={customer.CustomerType==2 ? "success" :"secondary"} onClick={()=>changeCustomerType(2)}>Purchase as Individual</Button>
              <hr/>
              <Form id="frm" onSubmit={sbFrm}>
                {customer.CustomerType!=0 &&
                  <BuyStep2DetailsForm/>
                }
                
              </Form>
          </Card.Body>
          <Card.Footer className="text-muted">
            <Button onClick={()=>setCurrentstep(1)}  variant="secondary" style={btnStyle}>Previous</Button>
            &nbsp;
            <Button disabled={!confirmmydata} variant="primary" type="submit" form="frm" style={btnStyle} >Next</Button>
        </Card.Footer>
        </Card>
        </>
)
}
