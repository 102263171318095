import React,{useRef,useState} from 'react'
import {Form,Card,Button,Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'


export default function PasswordRecover(props) {
    const RecEmail=useRef()
    const [msg,setMsg]=useState('')
    const [err,setErr]=useState(0)

    const sendResetPwd=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('Email',RecEmail.current.value)
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        axios.post(process.env.REACT_APP_API_SERVER+'pwdrecover',formData,{}) 
        .then(res=>{
            if (res.data==0){
                RecEmail.current.value=''
                setErr(1)
                setMsg('We have sent you instructions for setting your password to the email address you submitted. You should be receiving it shortly.')
            }else{
                setErr(2)
                setMsg('Invalid Emal Account.')
            }
        })

    }

    return (
        <Card className="text-center">
        <Card.Header>
        Password reset
        </Card.Header>

        <Card.Body>
        
        {err==0 && 
        <>
        Please enter the email address that you used upon registration at seametrix.net and we'll send you an email with instructions on how to reset your password.
        <br/><br/>
            <Form onSubmit={sendResetPwd}>
            <Form.Group>
                <Form.Control ref={RecEmail}  autoComplete="xx@joijfoer.com" type="email" required placeholder="Your Email"></Form.Control>
            </Form.Group>
            <Button type="submit" className="w-40" style={{backgroundColor:"#b053af",borderStyle:"none","width":"200px"}}>Reset My Password</Button>
            </Form>
        </>
        }

        {err==1 &&
            <Alert variant="success">
                {msg}
            </Alert>
        
        }

        {err==2 &&
            <Alert variant="danger">
                {msg}
            </Alert>
        
        }


        <br/>
        <Link style={{color:'gray'}} onClick={()=>{props.setPwdrec(false)}}><i>Return to Login</i></Link>

        </Card.Body>

        </Card> 
    )
}
