import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {Accordion,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'

export default function InvoiceDets(props) {
    const [orderitems,setOrderitems]=useState([])
    const [isLoading,setIsLoading]=useState(true) 

    useEffect(()=>{
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('otoken',props.otoken)

        axios.post(process.env.REACT_APP_API_SERVER+'orderitems',formData,{}) 
        .then(res=>{  
            setOrderitems(res.data)
            setIsLoading(false)
        })

    },[])


    const orderItemsList=orderitems.map(item=>{
        return(
            <tr>
                 <td>{item.ItemTitle}</td>           
                <td>
                {item.ItemDescription}
                {(item.ReNewedOrderID>0 && props.invoices) &&
                    <>
                        <br/><br/>
                        <font color="gray">
                        Related Order Key: 
                        <a href={props.invoices.filter(xitem=>xitem.OrderID==item.ReNewedOrderID)[0].InvoiceLink} target="_blank">
                        {props.invoices.filter(xitem=>xitem.OrderID==item.ReNewedOrderID)[0].Timestamp}
                        </a>
                        </font>
                    </>
                }
                </td>
                <td>{item.ItemNumberOfYears}</td>
                {props.invLink.length>0 &&
                <>
                <td>{item.ADate}</td>
                <td>{item.EDate}</td>
                </>
                }

            </tr>
        )
    })
    if (isLoading){
        return(
            <>
            <div className="container" style={{minHeight:"110vh"}}>

            <br/>
            <Alert  variant="warning">
                Loading...Please Wait
            </Alert>
            </div>
            </>
    
        )
    }else{


        return (
            <>
            {props.invLink.length>0 && 
                <p align="right">
                <Button variant="info" href={props.invLink} target="_blank">Invoice/Receipt</Button>
                </p>

            }

            <Table>
                <thead>
                    <tr>
                        <th>Licence(s)</th>
                        <th>Description</th>
                        <th>Years</th>
                        {props.invLink.length>0 && 
                        <>
                        <th>Activation Date</th>
                        <th>Expiration Date</th>
                        </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {orderItemsList}
                </tbody>
            </Table>
            </>
        )
    }

}
