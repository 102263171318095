import React,{useContext,useState} from 'react'
import {UserContext} from './UserContext'
import {Navbar,Nav,NavDropdown,Alert} from 'react-bootstrap'
import {Link,NavLink} from 'react-router-dom'
import { FaListAlt,FaDownload,FaCreditCard,FaFileInvoice,FaInfoCircle,FaWallet,FaAddressCard } from 'react-icons/fa'
import smtrxLogo from '../smtrxLogo.png'

export default function DashboardNav() {
    const {usertoken}=useContext(UserContext)
    const linkStyle={color: '#f0f0f0', textDecoration: 'none',fontWeight:'bold'}
    const linkActiveStyle={color: '#f5707a', textDecoration: 'none',fontBold:'true'}
    const {customername,setCustomername}=useContext(UserContext)
    const {setIsAuth}=useContext(UserContext)
    const {setUsertoken}=useContext(UserContext)
    const {isAuth}=useContext(UserContext)
    
    
    const logout=()=>{
        localStorage.setItem("userToken", null)
        localStorage.setItem("Customername", null)        
        localStorage.setItem("Auth", 0)
        setCustomername(null)
        setUsertoken(null)
        setIsAuth(false)
        
    }
    
    return (
        <>
        
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Link to="/dashboard"><img src={smtrxLogo} style={{width:200, marginTop: -7}} /></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="container-fluid">

                <Navbar bg="dark" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="container-fluid">
                <Nav.Link></Nav.Link>
                <Nav.Link></Nav.Link>
                <NavLink to="/dashboard/licencedetails" style={linkStyle} activeStyle={linkActiveStyle}>
                <FaListAlt />
                &nbsp;
                Licence Details
                </NavLink>

                <Nav.Link></Nav.Link>
                <Nav.Link></Nav.Link>
                <NavLink to="/dashboard/download" style={linkStyle} activeStyle={linkActiveStyle}>
                <FaDownload />
                &nbsp;                
                Download Seametrix
                </NavLink>

                <Nav.Link></Nav.Link>
                <Nav.Link></Nav.Link>
                <NavLink to="/dashboard/buy" style={linkStyle} activeStyle={linkActiveStyle}>
                <FaCreditCard />
                &nbsp;                
                Buy Seametrix
                </NavLink>


                <Nav.Link></Nav.Link>
                <Nav.Link></Nav.Link>
                <NavLink to="/dashboard/invoices" style={linkStyle} activeStyle={linkActiveStyle}>
                <FaFileInvoice />
                &nbsp;                
                Invoices
                </NavLink>


                <Nav.Link></Nav.Link>
                <Nav.Link></Nav.Link>
                <NavLink to="/dashboard/support" style={linkStyle} activeStyle={linkActiveStyle}>
                <FaInfoCircle />
                &nbsp;                
                Support
                </NavLink>               

                </Nav>

            </Navbar.Collapse>
        </Navbar>






                
                <NavDropdown className="ml-auto" title={customername} id="basic-nav-dropdown">
                        <NavDropdown.Item><b>{customername}</b></NavDropdown.Item>
                        <NavDropdown.Divider />
                        
                        <p align="center">

                            <NavLink to="/dashboard/billingdetails">Billing Details</NavLink>
                        </p>
                        <p align="center">
                            <NavLink to="/dashboard/account">Account Details</NavLink>
                        </p>                        
                        <p align="center">
                            <NavLink to="#" onClick={logout}>Logout</NavLink>
                        </p>  
                    </NavDropdown>
                </Nav>

            </Navbar.Collapse>
        </Navbar>



        </>
    )
}
