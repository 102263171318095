import React ,{useContext,useState,useEffect} from 'react'
import {Nav,Alert,Card,Button,Form,Table} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import {UserContext} from './UserContext'
import PaypalExpressBtn from 'react-paypal-express-checkout'
import {Redirect,useHistory} from 'react-router-dom'
import CancelOrder from './CancelOrder'



export default function BuyStep5PP() {
    const {items,setItems,setCurrentstep,order,setOrder,confirmmydata,setConfirmmydata,customer,setCustomer}=useContext(OrderContext)
    const {usertoken}=useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const history=useHistory()
    const [error, setError] = useState(false);

    const clientCreds = {
        sandbox:    'Aa4NYrG6C-3uMfvYuDBQHgnIRtYwWeXxA2dAlMzG2QMtTMFuG7_79xC-DJrxE5zmnqhjsbxEzdlTasZf',
        production: 'Aap2iIwrLdvUUZaoisi11M4LN3f2U9VsbIZ8GHselyeY89ZwW5YnQs1ZN6ji0-hi1F98VnfGcg4Gzb0f',
    }

    //const [client,setClient]=useState('AVw-DY5IxgXq5Z5Y_e_-totEg27toPfiOYth-EtEI2AskEy2z5x3ZLNxOJXDNlGFQB_U0qMHQzycaItC')
    const [env,setEnv]=useState('production')
    const [currency,setCurrency]=useState('EUR')
    const [client,setClient]=useState(clientCreds)

    const testPP=()=>{
        
        let payment={}

        payment.atoken=process.env.REACT_APP_API_ACCESS_TOKEN
        payment.otoken=order.otoken
        
        axios.post(process.env.REACT_APP_API_SERVER+'paypalcheckout',payment,{
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }
          }) 
          .then(res=>{
                console.log(res)
          })         
    }

    const onSuccess = (payment) => {
        setIsLoading(true)
        payment.atoken=process.env.REACT_APP_API_ACCESS_TOKEN
        payment.otoken=order.otoken
        
        axios.post(process.env.REACT_APP_API_SERVER+'paypalcheckout',payment,{
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }
          }) 
          .then(res=>{
            //if (res.data.err==0){
                setIsLoading(false)
                history.push('/dashboard/licencedetails')
            //}


          })                
    }

    const onCancel = (data) => {
        setError(true)
        setInterval(() => {
            setError(false)
        }, 5000);
    }

    const onError = (err) => {
        setError(true)
        setInterval(() => {
            setError(false)
        }, 5000);
    }


    return (
        <div>


            <hr/><h4>Amount: {order.finalCost}&euro;</h4><hr/>
            {!isLoading && 
            <PaypalExpressBtn 
                env={env} 
                client={client} 
                currency={currency} 
                total={order.finalCost} 
                onError={onError} 
                onSuccess={onSuccess} 
                onCancel={onCancel} 
                style={{
                    size: 'large',
                    color: 'blue',
                    shape: 'rect',
                }}
            />
            }
            <br/><br/>

            {isLoading &&  <><hr/><Alert  variant="warning">Processing Your Order...Please Wait</Alert><hr/></>}
            {error && <><hr/><Alert  variant="danger">Transaction canceled.</Alert><hr/></>} 
        </div>
    )
}
