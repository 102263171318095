import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {Nav,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import { FaListAlt,FaDownload,FaCreditCard,FaFileInvoice,FaInfoCircle, FaChessQueen } from 'react-icons/fa'
import {Redirect,useHistory} from 'react-router-dom'

export default function LecencesActivePreviousDocuments(props) {
    
    const {isAuth,usertoken}=useContext(UserContext)
    const [order,setOrder]=useState(0)

    useEffect(()=>{
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        formData.append('ReNewedOrderID',props.ReNewedOrderID)
        axios.post(process.env.REACT_APP_API_SERVER+'orders',formData,{}) 
        .then(res=>{  
            setOrder(res.data[0])
            console.log(res.data[0])
        }) 
    },[])     

    return (
        <>

        
        <br/><br/>
        <table width="100%">
        <tr><td><b>Past Invoice/Receipt</b></td></tr>  
        <tr><td>
        <a href={order.InvoiceLink} target="_blank">
        Order #{order.Timestamp} 
        </a>
        </td></tr>
        </table>


        </>
    )
}
