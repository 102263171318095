import React from 'react'

export default function RegTerms() {
    return (
        <div style={{ textAlign:'justify',textJustify:'inter-word',color:'#585757'}}>

The Services of the Website www.seametrix.net (hereinafter the “Website” of “seametrix.net”) are offered to the user (hereinafter “User”, “Subscriber” or just “You”) by the company “Seanergix Ltd” (hereinafter the “Company”) under the following Terms and Conditions, which you are requested to read carefully and use the Website’s Services only if you totally agree with them and accept their full application.
By using the Website’s Services by any means, you totally accept these Terms and Conditions. The Terms and Conditions, as well as the Privacy Policy of seametrix.net, set forth herein are the only legally bounding agreement between you and the Company and they have full force, just as if they had been signed in written. If you do not agree to these Terms you should not use seametrix.net or any other service arising from it.
<br/><hr/>
<p align="center"><span style={{color:'#f36459'}}><b>INTRODUCTION</b></span></p>
<br/>
Our Website is offering general information about the Company and about its software products. “Seametrix” is a SAAS (Software As A Service) windows desktop application (hereinafter “Seametrix” or the “Software”), offered by the Company and is addressed mainly to shipping related professionals and companies, across the world, providing relevant to the shipping industry services and various technical analysis reports.
<br/><br/>
Any information provided by the Website is only offered as general public information about the products and related topics and it shall in no way substitute the specialized and personalized opinion of professionals, which you should always seek on your specific business issues. The Company does not in any way guarantee the accuracy and correctness of the information you will find in the Website and it bears no responsibility for such information. For more information on this issue please refer to Article 6 below.
<br/><br/>
The Company reserves the right to modify at any time the contents and scope of these Terms and Conditions even without notice. For this reason, we encourage you to periodically visit this page in order to keep informed on any change. In the event of any subsequent amendment of the Terms and Conditions, your use of the Website at a future date will be taken to imply your acceptance of the new Terms and Conditions.
<br/><br/>

<span style={{color:'#f36459'}}><b>1. TRADEMARKS AND INTELLECTUAL PROPERTY RIGHTS</b></span>
<br/><br/>
1.1 The Website www.seametrix.net is intellectual property of the Company according to all applicable European laws and international conventions about copyright, as amended and currently in force. The Company maintains exclusively any intellectual property rights on the design, source code, database and the entire content of the Website (including but not limited to pictures, graphics, videos, photographs, texts, scientific presentations and studies, services and all other files of this Website) either directly as creator or indirectly, licensed by the respective creators. Violation by any means of these rights will draw the subsequent penalties and liability defined by applicable laws. Indicatively and non-exclusively: copying, modifying, publishing, distributing by any means of texts, photographs, databases and of the content of the Website without prior written permission of the Company, is prohibited. Furthermore copying, modifying, decompiling or by any means unauthorized use of seametrix.net software is prohibited.
<br/><br/>
1.2 You acknowledge that all intellectual and industrial property rights subsisting in or used in connection with the Software and the Services, including its images and presentation and all information relating thereto are the property of the Company and/or its licensors. All Applications, Data, databases, maps, graphics, texts, pictures, videos, information, documentation, trademarks and trade names, the Software source code, its design and any other copyrighted work contained in or presented with Seametrix, including without limitation: Services, Data of any kind, maps, map layers, ports & terminals, sea distances, waypoints, routing points, GIS data of any kind, icons, distance algorithms, Seametrix name and logo, technical analysis reports, voyage estimation styling/format, user interface etc. are fully owned by the Company and/or third parties who have provided the Company with adequate licenses, and they are protected by all applicable intellectual property laws and international treaties. By downloading any version of Seametrix Software, you do not purchase the Software; you only acquire a time limited license to use it (subscription), under the terms and conditions of the End User License Agreement (“EULA”) and you acknowledge and agree that no title, copyright or other proprietary rights in the Software or implied partnership are transferred to you hereby.
<br/><br/>
1.3 All trademarks, brand names and logos appearing on the Website are either registered trademarks of the Company or property of seametrix.net and/or third parties, companies or respective owners and they are protected by the relevant provisions of all applicable trademark laws, and by international conventions and treaties. Use of these trademarks, brand names and logos without license is absolutely prohibited. In case of questions relating to the content of this Website, please refer to the Company.
<br/><br/>
1.4 You acknowledge that civil and criminal penalties may be incurred in the event of any infringement of the Intellectual Property Rights in relation to the Website and the Software and that any such infringement by you may result in incalculable damage and/or loss to the Company. The User undertakes to indemnify the Company in full, against all loss, damage, costs and expenses, which may be incurred by the Company by reason of any such infringement by the User.
<br/><br/>
<span style={{color:'#f36459'}}><b>2. USER REGISTRATION</b></span>
<br/><br/>
2.1 In order to try or purchase the Seametrix Software you have to create a web account in seametrix.net. Once logged in your web account, you will be able to access/create your credentials for login into the application. To create an account, you must be aged 18 and over and have full legal capacity. Submission of the registration form in which you have entered your data is a valid declaration of intent. During registration, you must enter your true information. It is your responsibility to keep your information updated and full. Regarding your personal data protection, please refer to our Privacy Policy.
<br/><br/>
2.2 To register an account and access the services of Seametrix you are required to submit a valid e-mail address, so that your account can be activated, and a password. Your registration is complete when you verify your e-mail. Once the registration procedure is completed, you will receive a confirmation e-mail at the e-mail address you submitted. If you have chosen the trial version, you will receive a second email containing a direct download link for downloading Seametrix installation file and your necessary credentials. If you subscribe for the paid Seametrix standard version or for the Seametrix Plus extended version of the Application you will have to pay the respective annual fee by using one of the offered ways of payment (credit card, bank transfer or PayPal etc.). Once the payment is verified, you will be able to create your own/desired credentials (username & password) for logging in the application, and will then be granted full access to the Services of Seametrix or Seametrix Plus for one year. If you choose to install the Seametrix Trial, after the trial period you may be asked if you wish to subscribe for the Seametrix standard or for the Seametrix Plus.
<br/><br/>
2.3 You are personally and solely liable for all actions which take place via your user account and for your proper Log-Out after the end of each use of the website services. You are obliged to immediately inform the Company in case you become aware of any unauthorized use of your account or any possible security violation. You are solely responsible for the careful use of your account. The Company is not liable for any damage caused to users who fail to comply with the present condition.
<br/><br/>
2.4 As a User you are responsible to keep your username and password secure and you agree not to disclose them to any other individual or organization not authorized to have access to Seametrix. In case the Company finds out of any unauthorized use of a user’s account, such account may be terminated without prior notice at the Company’s sole discretion.
<br/><br/>
 


<span style={{color:'#f36459'}}><b>3. SEAMETRIX SOFTWARE</b></span>
<br/><br/>
3.1 After creating an account on seametrix.net you will be able to download and use any version of the Seametrix Software. Seametrix Services are mainly targeted at entrepreneurs and employees of companies operating in the shipping industry. The Software provides the ability for the end user to search optimal sea routes and distance results between ports, taking into account parameters such as pirate zones, SECA zones, navigational restrictions, Great Circle versus Rhumbline routing etc. In particular, Seametrix provides the following services:
<br/><br/>
3.1.1 A Module with sea distances and sea routing (Distance Grabber). This Module provides distances between two or multiple ports or coordinates and suggests best available routing, which may be customized by the User. The provided routing is subjective and optional; it may even contain deviations from actual routes, for which the Company bears no responsibility. The Module is not intended for use on the ship or to replace any navigation tool. Its only purpose is to be used as a reference in order to calculate travel times and costs in the office. The subscribed user, may request up to 99 distance pairs per day. This restriction is necessary for the security of our Data and abuse prevention.
<br/><br/>
3.1.2 A Module for travel/voyage costs calculation. This Module uses mathematical operations, taking into account information such as distance, time, fuel consumptions etc. for a planned/hypothetical trip and it provides results such as required freight, voyage expenses, net profits etc.
<br/><br/>
3.1.3 A Module providing Weather Information, which is renewed approximately every six hours. The User may see a weather forecast map for the next 72 hours, as well as area map forecasts of up to 234hrs. This Service is provided by a Third-Party Provider. We suggest you to read Third Party Provider’s Terms and Conditions carefully before using this service.
<br/><br/>
3.1.4 A Module with daily-renewed oil prices information for about 100 ports worldwide. This Service is based on prices provided by a Third Party Provider, (namely Fuel Trade – FT Maritime Services LTD). This Module also shows energy commodities prices mainly in real time (some of them with 15-25’ delay or End Of Day), provided also by a Third Party Provider, namely by Trading View.
<br/><br/>
3.1.5 “Seametrix Plus” Module provides technical and Chart Analysis reports, only available to Subscribers who choose the “Seametrix Plus” package. Seametrix Plus is designed for Users who choose this module for subscribing to Chart Analysis reports and commentary on Stock indices, commodities (i.e. oil), foreign exchange rates, equities etc.
<br/><br/>
3.2 According to the contained Modules and the user restrictions, there are three versions of Seametrix Software:
<br/><br/>
3.2.1 Seametrix Trial: We offer a 3 month free trial of Seametrix. The trial consists only of the Distance grabber module 2.1.1 and the Bunker Prices module 3.1.4. The only restriction is that the Trial User may only request results for up to 5 distance pairs per day.
<br/><br/>
3.2.2 Seametrix: The standard version of Seametrix. It is a full version of the Modules 3.1.1 – 3.1.4 and is offered with an annual subscription fee; it has a 99 distance pairs request limitation per day.
<br/><br/>
3.2.3 Seametrix Plus: The extended version of Seametrix, which contains access to all Modules listed under 3.1.1 – 3.1.5 above.
<br/><br/>
3.3 Technical Requirements: In order to use Seametrix you will need a desktop computer with:
<br/><br/>
Windows 7 / 8 / 10 32/64bit with installed Microsoft 4.5.net Framework and above.<br/>
Pentium dual core upwards<br/>
4GB RAM+<br/>
4GB HDD+<br/>
Broadband internet connection.<br/>
1360×768 minimum display resolution<br/>
<br/>
<span style={{color:'#f36459'}}><b>4. PRICING AND PAYMENTS</b></span>
<br/><br/>
4.1 Pricing: All prices on our Website are shown in euros. The prices of our Software products may be subject to changes without notice. As existing and applicable each time is deemed the price displayed on the Website at the time of the order. Prices changes will not affect orders in respect of which we have already sent you a confirmation of order. All prices are shown exclusive of VAT, due to different VAT conditions worldwide. Dependent on your selected country and whether you purchase as an individual or as a company (with a valid VAT number), VAT (if applicable) will be shown to you, during the checkout process for purchasing our Software.
<br/><br/>
4.2 Payment methods: Subscription orders may be paid by direct bank transfer, by using a credit card, a debit card or by PayPal. In case you choose direct bank transfer, you have to use your Order ID as the payment reference. Your credentials/account will be inactive until the funds have cleared in our account.
<br/><br/>
4.3 Transaction security: Our Company uses the services of ………………. Payment gateway, to provide the highest level of security for payments processed through this Website. ……………………. ensures your security by encrypting the connection when transmitting banking information on the network, and by safeguarding your financial data throughout the whole ordering and payment process. The security of our Website is validated through an SSL Certificate. Please note that if the security department suspects fraud, we have the right to cancel the transaction for security reasons. In most cases, you will be notified of authorization or fraud issues, if they occur, before you complete the checkout process. Once you have completed the payment process, you will receive a confirmation e-mail for your order.

<br/><br/>

<span style={{color:'#f36459'}}><b>5. TERMINATION POLICY – CANCELLATION</b></span>
<br/><br/>
5.1 These Terms and Conditions are effective upon acceptance by the User and for as long as your account in seametrix.net exists, unless terminated either by you or by the Company.
<br/><br/>
5.2 Termination by the User: You may terminate your registration on the Website at any time by notifying the Company. Upon such notification, your access to the Software will be terminated as well. All charges/subscription fees regarding the services provided by Seametrix are not refundable. We do provide you with a long term free trial option upon registration, and because of the nature of the Services, cancelling of your subscription at any time WILL NOT ENTITLE YOU TO ANY REFUNDS! BY ACCEPTING THESE TERMS AND CONDITIONS AND BY PURCHASING A SEAMETRIX LICENSE, YOU AGREE THAT YOU ARE NOT ENTITLED TO WITHDRAW FROM YOUR ORDER DURING THE FIRST 14 DAYS OF YOUR SUBSCRIPTION.
<br/><br/>
5.3 Termination by the Company: The Company may terminate your Agreement at any time as soon as we give you notice, unless otherwise set out in this Agreement. We will give you as much prior notice as we reasonably can before we cancel your access to the Services. However, the Company retains the right to terminate this Agreement without warning or notification and to cancel the Service immediately without refunding of any charges, if there is a reasonable cause to do so. In particular, the Company has the right to cancel a subscription and deactivate a User’s account immediately, indicatively and not restrictively, in case any of the following occur:
<br/><br/>
5.3.1 a) we reasonably suspect fraud by you or any other person in connection with the use of the Software by you or on your behalf or if we have evidence of you or your account is attempting to circumvent the Seametrix Security System;
<br/>
5.3.1 b) any amount owed to Us in respect with the Service (which is not the subject of a valid dispute) is not paid by its due date although we have given you notice regarding the payment and you have failed to pay that amount.
<br/>
5.3.1 c) you fail to comply with any term or condition set out in this Agreement;
<br/>
5.3.1 d) we are required to do so to comply with an order, instruction, request or notice by any competent authority;
<br/>
5.3.1 e) we are otherwise entitled to do so under this Agreement;
<br/>
5.3.1 f) the Software or Services are deemed obsolete with any hardware required to access the Application, or Service is retired;
<br/><br/>
5.4 Termination consequences: Upon termination, your account on the Website will be deactivated and you must immediately delete all the Software, together with all copies, adaptations and merged portions thereof in any form, which might be in your possession. Obligations to pay accrued charges or fees shall survive the termination of your account. Any termination of your account shall not affect any accrued rights or liabilities of either party nor shall it affect any provision of these Terms or of the EULA that is expressly or by implication intended to come into or continue in force on or after such termination.
<br/><br/>
5.5 The Company reserves the right to suspend at any time the operation of the Software.
<br/><br/>
<span style={{color:'#f36459'}}><b>6. LIMITATION OF LIABILITY</b></span>
<br/><br/>
6.1 At no time shall the Company and/or its licensors be liable to you or to any third party for any kind of direct or indirect financial loss, including without limitation, lost profits, wealth or consequential damages to your portfolio or assets resulting from the use of information presented on the Website or through the Software. You bear exclusive responsibility for any decision made or action taken by you or by such third party in reliance on such content. Neither the Company or its Licensors, nor its Third Party content providers shall be liable for any misguidance, errors, inaccuracies, delays, or any actions taken based on such reports. By accepting these Terms you totally agree with and acknowledge this clause.
<br/><br/>
6.2 The Company’s liability under these Terms is limited. You agree not to raise any claims against the Company, its directors or representatives or its Third Party providers, arising from or related to any direct or indirect, consequential, incidental or special damages, loss of whatever profits, lost savings, loss of data or damages to your tangible property, imposed on you from your use of, misuse, or inability to use the Website, the Software and the provided Services including the case of negligence, even if the Company knew, or should have known or has been advised of the possibility of such damages. This limitation applies to any Services, contents or other material provided by seametrix.net, the Software, third party information, data and websites, or Third-Party programs or services, and claims for breach of contract, breach of warranty, guarantee or condition, strict liability, negligence, or other tort to the maximum extent permitted by applicable law.
<br/><br/>
6.3 Third Party Content: By using seametrix.net you understand and agree that the Company does not control and is in no manner responsible for the content of information provided by Third Party Providers, their services/products, advertisements, availability, price and pricing of any other products etc. Furthermore, the Company is unable to verify the accuracy of content received by Third Parties, including weather information and forecasts, bunker prices, commodity prices etc. and assumes no liability whatsoever for any errors and / or misleading information provided by such Third Party Providers. The responsibility for the completeness, accuracy, adequacy, legitimacy and fitness for purpose of such content, belongs to the websites / companies that provide this content. The accuracy of reliability of Third Party data is in no way guaranteed by the Company and under no circumstances shall the Company be liable to you for any damages arising out of the use of such data, including, without limitation, damages resulting from lost data, lost profits, recovering costs, costs of substitute data, claims by third parties or for other similar costs etc. Use Third Party provided Services at your own risk and initiative.
<br/><br/>
6.4 Even in the case of proven direct damages, ordered compensation and/or pecuniary compensation for moral damage sustained by a User, the total amount of monetary compensation by the Company and its third-party providers shall in no event exceed the amount of the User’s annual subscription during the year the damages incurred, if any.
<br/><br/>
6.5 Damage Compensation: The User is liable towards the Company and its Third Party providers, for any damage caused as a result of illegal or harmful use of the Website from the User’s side, as well as for the illicit use of the Services provided by the Company or any use in a manner that does not comply with the present terms and conditions. You agree to indemnify, defend and hold us, and our officers, directors, employees, shareholders, agents, licensors and suppliers harmless immediately on demand, from and against all liability, damages, losses, costs and expenses, including legal fees, arising out of any breach of these Terms by you or other liabilities arising out of your use of Seametrix and its Services.
<br/><br/>
<span style={{color:'#f36459'}}><b>7. NO WARRANTY</b></span>
<br/><br/>
7.1 All Services, Data, and any kind of content of the Website and the Software is provided “as is” without any warranties of any kind, to the maximum extent permitted by applicable law. Seanergix Ltd excludes and expressly disclaims all warranties and conditions not stated in these Terms, including but not limited to, any implied warranties of quality, accuracy, merchantability, non-infringement, fitness for a particular purpose or to achieve particular results. All contents, information, Data, services etc. provided by Seametrix.net should under no circumstances be construed as valid information, advice or exhortation for a particular action. Furthermore, information, and technical solutions and result provided by seametrix.net Website and Seametrix Software, such as calculated costs, sea routes etc. must be seen only as estimations based on statistical data and algorithmically processed and calculated results. They are not in any way exclusive or unique and the Company bears no responsibility for any actions taken by the Users based on such information. Users may not use or post such information in any media or places without written permission of the Company.
<br/><br/>
7.2 More specifically, although the Company and/or its Licensors have collected and identified all available Data (such as port data, port names and codes, the GIS data of any kind, the sea distances, including the Rhumbline and Great Circle calculated results) using the best possible information available, and the geometry broadly known, the provided results (including distances, travel time and costs calculations, routing information etc.) are algorithmically and/or computer calculated results only, NOT definitive, NOT necessarily accurate or correct, and are used at your own risk. You acknowledge that in no circumstances should the Software be used as a navigation tool. Given the nature of the Services, the Company is not, under any circumstances, including negligence, responsible for any kind of damage caused to the User of seametrix.net and of the Software, the use of which is at the initiative of each user individually.
<br/><br/>
7.3 All information, comments, tools and financial instruments used as well as all the content provided within the Technical Analyses reports is expressed in good faith, but it is by no means guaranteed. All of the Technical Analysis reports’ content, is provided on an “as is” and “as available” basis. The Company makes no representations or warranties of any kind, expressed or implied, (a) as to the operation, correctness, and proper apply of the tools used, (b) as to the validity or accuracy of the content or materials provided on these reports or (c) as to the fitness of the information for any purpose d) as to the commentators’ expressed views, comments and conclusions. The Company is not responsible for any material contained in the Technical Analysis reports, and it is not implied that the Company agrees with any material or expression of opinions contained therein. The Company is not authorized to conduct investment business and does not provide investment advice or recommendations of any kind.
<br/><br/>
7.4 The Website is only available where suitable access to the Internet is available. It is your responsibility to ensure that you have a suitable Internet connection for use of the Services. You must also ensure that Seametrix Services are not blocked by Firewalls and/or anti-virus software. Whilst we will endeavor to maintain the availability of seametrix.net and its Services, we make no explicit warranty that those will meet your requirements or will be uninterrupted, timely, or error-free, that defects will be corrected or that seametrix.net or the servers that make it available are free of viruses or bugs or other harmful components. If you are aware of any error, please contact us by email at support@seametrix.net and we will endeavor to correct it. Although not intended, as with all computer Software and Services, it is possible a defect to cause your computer to function improperly.
<br/><br/>
7.5 You use seametrix.net, the Software and the Services provided at your own risk and responsibility, understanding and accepting that their use may cause data loss or damage of any kind to you or your computer. You assume the entire risk as to the quality and performance of the Software. The Company does not warrant that Seametrix will meet your requirements or expectations.
<br/><br/>
<span style={{color:'#f36459'}}><b>8. APPLICABLE LAW AND OTHER TERMS</b></span>
<br/><br/>
8.1 These Terms and Conditions constitute the entire Agreement between the Company and the User of the Website seametrix.net and are binding for these two parties only. You may access the Website and use the Services from anywhere in the world; however, these Terms shall apply regardless of how and from where you access the Website and its Services. Unless otherwise expressly agreed in writing, these Terms and the Privacy Policy constitute the sole and exclusive agreement between you and the Company with regard to the Website Services, and supersedes all prior proposals, representations, agreements, whether oral or written, and other communications between the parties relating to the subject matter set forth herein. No amendment of these Terms will be taken into consideration and become a part of these Terms and Conditions, unless expressed in writing and incorporated in it. For any dispute arising between the Company and any User an effort for an out-of-court settlement and compromise is required.
<br/><br/>
8.2 All the above-mentioned Terms and Conditions are stipulated as essential. Under these Terms you are granted specific legal rights, but as a consumer you may also have other rights that vary from country to country and are not affected. The limitations of liabilities and exclusions of implied warranties contained in these Terms shall apply to the fullest extent permitted by the laws of any at each time applicable jurisdiction. However, in case that any provision of these Terms becomes illicit or is held to be void or unenforceable in a specific jurisdiction, it shall automatically lapse and be deemed removed from these Terms, without prejudice to the validity of other Conditions and the remainder of such limitation or exclusion shall continue in full force and effect.
<br/><br/>
8.3 These Terms and Conditions, the Privacy Policy as well as any subsequent modification thereof, are governed and complemented exclusively by the Bulgarian law, EU law and all relevant international treaties, signed by Bulgaria. By accepting Terms and Conditions you accept the exclusive jurisdiction of the Courts of Sofia, Bulgaria.
<br/><br/>
8.4 Company shall not become or be deemed a partner or a joint venture with you by reason of the provisions of these Terms. These Terms and Conditions are personal to you and you may not assign, transfer, sub-contract or otherwise part with these Terms or any right or obligation under them without the Company’s prior written consent. In case of partial or total succession of the Company, the successor is bound by all rights and obligations included in these Terms.
<br/><br/>
8.5 If the Company determines in its sole discretion that you have breached these Terms and Conditions, the Company may take such action as it deems appropriate, including the immediate, temporary or permanent suspension or termination of your right to use our services. Failure or neglect by the Company to exercise any of its rights or remedies under these Terms will not be construed as a waiver of Company’s rights nor in any way affect the validity of the whole or part of this these Terms nor prejudice the Company’s right to take subsequent action.
<br/><br/>
8.6 Force Majeure Neither party shall be liable to the other for any delay, damages, misperformance or non-performance of its obligations under these Terms arising from any cause or causes beyond its reasonable control including, without limitation, any of the following situations affecting all involved parties’ premises, staff, offices, bank accounts, data centers, servers’ hardware and premises they are hosted at, such as buildings-companies-countries etc.:
<br/>
8.6 (a) act of God, governmental act, war, cyber-attack, bank strikes and failures of the banking systems, fire, flood, broad electricity blackouts, explosion, civil commotion, armed hostilities, act of terrorism, revolution;
<br/>
8.6 (b) blockade, embargo, strike, lock-out, sit-in, industrial or trade dispute to the extent to which those things do not involve employees of the party claiming relief,
<br/>
8.6 (c) adverse weather or disease;
<br/>
8.6 (d) act or intervention of a competent judicial or regulatory authority;
<br/>
8.6 (e) any event (including, without limitation, any act or omission of any third party) beyond its reasonable control which could not reasonably be planned for or avoided. Subject to the party so delaying promptly notifying the other party in writing of the reason for the delay and the likely duration of the delay, the performance of the delaying party’s obligations, to the extent affected by the delay, shall be suspended during the period that the cause persists.
<br/><br/>
 

<span style={{color:'#f36459'}}><b>9. COMPLAINTS AND NOTICES</b></span>
<br/><br/>
If you are unhappy about any aspect of the Services or any of our products, if you have any questions regarding these Terms and Conditions or if you wish to request any information from the Company, you may contact the Company at the following contact details:
<br/><br/>
SEANERGIX LTD<br/>
185, Vasil Levski str.<br/>
Floor 2 – Office 8<br/>
5800 – Pleven – Bulgaria<br/>
Tel : +359-64-970 900<br/>
+44-203-8687788<br/>
Email: support@seametrix.net<br/>
<br/><br/>
We will investigate any complaint in accordance with our complaint handling policy.
<br/><br/>
We will consider you have received information from Us if it is included on our Website or if it is directly communicated to you by phone, message, chat, email or mail using your most recent contact details given to Us.
<br/><br/>
If you do not agree to these Terms and Conditions you should not proceed to creating an account on the Website or using our Services.



        </div>
    )
}
