import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {CustomerContext} from './CustomerContext'
import {Accordion,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import { FaUniversity,FaPaypal,FaCreditCard,FaFileInvoice,FaInfoCircle, FaChessQueen } from 'react-icons/fa'

export default function Account() {

    const {isAuth,usertoken}=useContext(UserContext)
    const [customer,setCustomer]=useState({})
    const [isLoading,setIsLoading]=useState(true)  
    const [voldPwd,setVoldPwd]=useState(false)  
    const [oldPwd,setOldPwd]=useState('')
    const [newPwd,setNewPwd]=useState('')
    const [newPwdC,setNewPwdC]=useState('')
    const [pwdMatch,setPwdMatch]=useState(false)      
    const [err,setErr]=useState(false)
    const [suc,setSuc]=useState(false)
    const [pwdOK, setPwdOK] = useState(false)
    const [pwd1OK, setPwd1OK] = useState(false)
    
    useEffect(() => {


        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        axios.post(process.env.REACT_APP_API_SERVER+'customer',formData,{}) 
        .then(res=>{  
            setCustomer(res.data)
            setIsLoading(false)  
        })
        
    },[usertoken])

    const init=()=>{
        setVoldPwd(false)
        setOldPwd('')
        setNewPwd('')
        setNewPwdC('')
        setPwdMatch(false)
        setErr(false)

    }

    const chOldPwd=(e)=>{
        setOldPwd(e.target.value)
    }



    const chNewPwd=(e)=>{
        setNewPwdC('')
        setPwdMatch(false)
        setNewPwd(e.target.value)
        const pwd=e.target.value
        var az=0
        var nums=0
        var symbols=0
        var pwdl=0

        if (pwd.match(/[a-z]+/)){
            az=1
        }else{
            az=0
        }

        if (pwd.match(/[0-9]+/)){
            nums=1
        }else{
            nums=0
        }

        if (pwd.match(/[$@#&!%^*()]+/)){
            symbols=1
        }else{
            symbols=0 
        }      

        if (pwd.length>7){
            pwdl=1
        }else{
            pwdl=0
        }

        if (az==1 && nums==1 && symbols==1 && pwdl==1){
            setPwdOK(true)
        }else{
            setPwdOK(false)
        }

    }

    const chNewPwdC=(e)=>{
        setNewPwdC(e.target.value)
        if (newPwd==e.target.value){
            if (newPwd.length>5 && newPwd.length<13){
                setPwdMatch(true)
            }else{
                setPwdMatch(false)
            }
            
        }else{
            setPwdMatch(false)
        }
    }

    const checkNewPwd=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        formData.append('pwd',newPwd)
        axios.post(process.env.REACT_APP_API_SERVER+'setpwd',formData,{}) 
        .then(res=>{  

            if (res.data==0){
                init()
                setSuc(true)
                setTimeout(() => {
                    setSuc(false) 
                }, 3000);                
            }else{
                init()
                setErr(true)
                setTimeout(() => {
                    setErr(false) 
                }, 3000);

            }
            setPwdOK(false)
            setPwdMatch(false)
        })  
    }

    const checkOldPwd=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        formData.append('pwd',oldPwd)
        axios.post(process.env.REACT_APP_API_SERVER+'checkpwd',formData,{}) 
        .then(res=>{  

            if (res.data==0){
                setVoldPwd(true)
            }else{
                setVoldPwd(false)
                setErr(true)
                setTimeout(() => {
                    setErr(false) 
                }, 3000);

            }
        })        
    }


    if (isLoading){
        return(
         <div className="container" style={{minHeight:"110vh"}}><br/>
             <Alert  variant="warning">Loading...Please Wait</Alert>
         </div>
         )
         
     }else{    
        return (
            <div className="container">
                <br/>

                <Card className="text-center">
                <Card.Header>
                Account Details
                </Card.Header>
                <Card.Body>

                <b>Name:</b>&nbsp;{customer.CustomerName}&nbsp;{customer.CustomerLastName}
                <br/>
                <b>Registration Date:</b>&nbsp;{customer.RDate}
                <br/>
                <b>Email:</b>&nbsp;{customer.Email}                
                <br/><br/>
                <Alert  variant="info">Change your SEAMETRIX DASHBOARD password</Alert>
                If you change your password here, <b>it applies for your web account login only</b> and not for Seametrix.<br/>
                To change your username and password for Seametrix, go to Licence Details and click the "Credentials & Details" button,<br/>
                You are able to change your Seametrix credentials there.
                <hr/>
                {!voldPwd &&
                    <Form id="frm" onSubmit={checkOldPwd}>
                    <table width="100%">
                        <tr>
                            <td>Enter Your Current Password</td>
                            <td width="10"></td>
                            <td width="50%"><Form.Control value={oldPwd} onChange={chOldPwd} type="password" required placeholder="Current Password"/></td>
                            <td>

                            <Button  variant="primary" type="submit" form="frm">Submit Current Password</Button>
                            </td>
                        </tr>
                    </table>
                    
                    &nbsp;
                    </Form>
                }

                {err &&
                    <Alert  variant="danger">Wrong Password</Alert>
                }
                {suc &&
                    <Alert  variant="success">New Password Set!!!</Alert>
                }

                {voldPwd &&
                <>
                    <Alert  variant="success">Enter a New Password</Alert>
                    <br/>
                    <Form id="frm2" onSubmit={checkNewPwd}>
                    <table width="100%">
                        <tr>
                            <td>Enter Your New Password</td>
                            <td width="10"></td>
                            <td>
                            <Form.Control
                                style={{borderColor: pwdOK ? 'green': 'red'}} 
                                maxLength="12" 
                                minLength="8" 
                                value={newPwd} 
                                onChange={chNewPwd} 
                                type="password" 
                                required 
                                placeholder="* 8-12 characters with a mix of letters,numbers & symbols"

                                />
                            </td>

                        </tr>
                        <tr><td height="10" colSpan="3" ></td></tr>
                        <tr>
                            <td>Confirm Your New Password</td>
                            <td width="10"></td>
                            <td><Form.Control
                            disabled={!pwdOK}
                            style={{borderColor: pwdMatch ? 'green': 'red'}} 
                            value={newPwdC} 
                            onChange={chNewPwdC} 
                            type="password" 
                            required 
                            placeholder="Confirm New Password"/></td>

                        </tr>
                        <tr><td height="20" colSpan="3" ></td></tr>
                        <tr>
                            <td colSpan="3" align="right">
                            <Button  variant="danger" onClick={init}>Cancel</Button>
                            &nbsp;&nbsp;                            
                            
                            <Button disabled={!pwdMatch} variant="primary" type="submit" form="frm2">Set New Password</Button>
                            </td>
                        </tr>
                    </table>
                    
                    &nbsp;
                    </Form>
                </>

                }

                </Card.Body>
                <Card.Footer className="text-muted">
                
                </Card.Footer>
                </Card>

            </div>        
        )
    }
}
