import React ,{useContext} from 'react'
import {Nav,Button,Navbar} from "react-bootstrap"
import {OrderContext} from './OrderContext'


export default function BuyNav() {

    

    const {setCurrentstep,currentstep}=useContext(OrderContext)

    return (
        <Navbar bg="light" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">

            <Nav.Item>
                        <Button onClick={()=>{setCurrentstep(1)}} disabled={currentstep<2} variant={currentstep==1 ? "primary": "secondary"}>Step 1 - Choose Products</Button>
                    </Nav.Item>
                    &nbsp;
                    <Nav.Item>
                        <Button onClick={()=>{setCurrentstep(2)}} disabled={currentstep<3} variant={currentstep==2 ? "primary": "secondary"}>Step 2 - Billing Details</Button>
                    </Nav.Item>
                    &nbsp;
                    <Nav.Item>
                        <Button onClick={()=>{setCurrentstep(3)}}  disabled={currentstep<4} variant={currentstep==3 ? "primary": "secondary"}>Step 3 - Payment Selection</Button>
                    </Nav.Item>
                    &nbsp;
                    <Nav.Item>
                        <Button onClick={()=>{setCurrentstep(4)}}  disabled={currentstep<5} variant={currentstep==4 ? "primary": "secondary"}>Step 4 - Preview Your Order</Button>
                    </Nav.Item>
                    &nbsp;
                    <Nav.Item>
                        <Button onClick={()=>{setCurrentstep(5)}}  disabled={currentstep<6} variant={currentstep==5 ? "primary": "secondary"}>Step 5 - Checkout</Button>
                    </Nav.Item>


            </Nav>

        </Navbar.Collapse>
        </Navbar>

    )
}
