import React ,{useContext,useState,useEffect} from 'react'
import {OrderContext} from './OrderContext'
import {UserContext} from './UserContext'
import {Redirect,useHistory} from 'react-router-dom'

export default function BuyStep5BT() {
    const {order}=useContext(OrderContext)
    const history=useHistory()
    useEffect(()=>{

        history.push('/dashboard/order/'+order.otoken)
    })

    return (
        <div>
            <h1>Bank Transfer</h1>
        </div>
    )
}
