import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import {Nav,Alert,Card,Button,Form,Table} from "react-bootstrap"
import InvoiceDets from './InvoiceDets'
import {Redirect,useHistory} from 'react-router-dom'



export default function Order(props) {
    const params = useParams();
    const [order,setOrder]=useState([])
    const [isLoading,setIsLoading]=useState(true) 
    const history=useHistory()
    const {isAuth,usertoken}=useContext(UserContext)
    const [customer,setCustomer]=useState({})

    useEffect(()=>{
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('otoken',params.id)

        axios.post(process.env.REACT_APP_API_SERVER+'orderDetails',formData,{}) 
        .then(res=>{  
            setOrder(res.data)
            loadCustomer()
            setIsLoading(false)

        })
    },[])  



    const loadCustomer=()=>{
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        axios.post(process.env.REACT_APP_API_SERVER+'customer',formData,{}) 
        .then(res=>{ 
            //console.log(res.data) 
            setCustomer(res.data)
            setIsLoading(false)  
        })
    }

    const cancelOrder=(oid,ok)=>{

        if (window.confirm('CANCEL the order: #'+ok+'?')){
            
            const formData=new FormData()
            formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
            formData.append('XOrderID',oid)
            axios.post(process.env.REACT_APP_API_SERVER+'cancelorder',formData,{}) 
            .then(res=>{

                if (res.data==0){
                    //setLicences(restItems)
                    //props.setNumOfPendingLicences(props.numOfPendingLicences-items.length)    
                    history.push('/dashboard/licencedetails')
                } 
            })
            

        }
        
    }


    if (isLoading){
        return(
            <>
            <div className="container" style={{minHeight:"110vh"}}>

            <br/>
            <Alert  variant="warning">
                Loading...Please Wait
            </Alert>
            </div>
            </>
    
        )
    }else{      
        return (
            <div className="container">
            <br/>

            <Card className="text-center">
            <Card.Header>
            #{order.Timestamp} Order Details - Pending Activation
            </Card.Header>
            <Card.Body>
            <>

            <table width="100%"><tr><td align="left">
            

            Dear User,
                <br/><br/>
                Users wishing to pay by direct bank transfer, should kindly follow the instructions below:
                <br/><br/>
                <strong>1.</strong>&nbsp;Please pay to our below Bank Account (in Euros):<br/><br/>
                
                <strong>Beneficiary</strong>    :  Seanergix Ltd<br/>
                <strong>IBAN No</strong>         :  BE85 9676 6796 5406<br/>
                <strong>Account No</strong>      : 6679654<br/>
                <strong>Swift/BIC</strong>       : TRWIBEB1XXX<br/>
                <strong>Bank's name</strong> :  Wise Europe S.A<br/>
                <strong>Bank address</strong>:   54 Avenue Louise, Room S52 - Brussels - 1050 – Belgium<br/>
                <strong>Reference</strong>     :   {customer.CompanyName} / {localStorage.getItem('Customername')}<br/><br/>
                <strong>2.</strong>&nbsp;       Please make sure that the following will be used as reference: Order: #{order.Timestamp}<br/><br/>
                <strong>3.</strong>&nbsp;       All bank transfers should be free of commissions on our end. The due amount should be deposited in full & free of commissions into our bank account.<br/><br/>
                <strong>4.</strong> &nbsp;      Once the funds are cleared into our account, we will activate your Seametrix products within the same business day.<br/><br/>
                <strong>5.</strong>&nbsp;       If you believe your order’s activation/processing has been delayed, please email us at: support@seametrix.net<br/>
                <br/><br/>
                Thank You!<br/>
                The Seametrix Crew<br/>
                www.seametrix.net
                <hr/>
                <b>Order Date:</b> {order.ODate}<br/>
                <b>Total:</b> {order.FinalCost}&euro;<br/>
                <br/>
                <Button variant="danger" onClick={()=>cancelOrder(params.id,order.Timestamp)}>Cancel Order</Button>
                &nbsp;
                <Button variant="info" onClick={()=>{history.push('/dashboard/licencedetails/pending')}}>Return to Licences</Button>
                </td></tr></table>
            



            <br/><br/>

            <InvoiceDets otoken={params.c} invLink=''/>
            </>
            </Card.Body>
            <Card.Footer className="text-muted">
            </Card.Footer>
            </Card>

            </div>        
        )
    }
}
