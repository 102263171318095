import React ,{useContext,useState,useEffect,useRef} from 'react'
import {Nav,Alert,Card,Button,Form,Table} from "react-bootstrap"
import axios from 'axios'
import {CustomerContext} from './CustomerContext'
import {UserContext} from './UserContext'

export default function BillingDetailsCompany() {
    
    const {customer,setCustomer}=useContext(CustomerContext)

    const [countries,setCountries]=useState([])

    const [vatflag,setVatflag]=useState(0)
    const [vat,setVat]=useState(0)
    const [viescode,setViescode]=useState('')
    const [eu,setEu]=useState(0)   
    const [vatIsValid,setVatIsValid]=useState(false)
    const [vatresponse,setVatresponse]=useState('')
    const [saveRes,setSaveRes]=useState(0)

    const VATRef=useRef()

    useEffect(()=>{

        fetch(process.env.REACT_APP_API_SERVER+'countries')
        .then((res)=>res.json())
        .then((data)=>{           
            setCountries(data)
            
            var cusCoun=data.filter(item=>item.CountryCode===customer.Country)[0]
            //console.log(customer)
            var vVPC=0
            if (cusCoun.EU==1){
                if (cusCoun.VATFLAG==1){
                    vVPC=cusCoun.VAT
                }
            }            
            setVatIsValid(true)
            
        })
        
        setViescode(customer.VIESCode)
        setEu(customer.EU)   
        setCustomer({...customer,CustomerType:'1'})
    },[])

    const countriesList=countries.map(item=>{

        return(
            <option key={item.CountryCode} value={item.CountryCode}>{item.Country}</option>
        )
    })

    const selCountry=(e)=>{
        
        changeFldValue(e,'Country')
        
        VATRef.current.value=''
        const counCode=e.target.value
        const selectedCountry=countries.filter(item=>item.CountryCode==counCode)[0]

        setEu(selectedCountry.EU)
        setVatflag(selectedCountry.VATFLAG)
        setVat(selectedCountry.VAT)
        setViescode(selectedCountry.VIESCode)
        setVatresponse('')
        
        var vatValuePerCent=0
        if (selectedCountry.EU==1){
            if (selectedCountry.VATFLAG==1){
                vatValuePerCent=selectedCountry.VAT
            }
        }
        

    }

    const chVatnum=(e)=>{
        const vat=e.target.value
        changeFldValue(e,'VAT')
        
        
        
        if (eu==1){
            if (/^\w+$/.test(vat)){
                const formData=new FormData()
                formData.append('Country',viescode)
                formData.append('VAT',vat)

                formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
                axios.post(process.env.REACT_APP_API_SERVER+'checkVat',formData,{})  
                .then(res=>{
                    setVatresponse(res.data.response)
                    setVatIsValid(res.data.valid_vat)
                }) 
            }else{ 
                setCustomer({...customer,VAT:''})
                setVatIsValid(false)                
            }
        }else{
            if (vat.length<5){
                setVatresponse('Please enter a valid Registration/Identification No') 
                setVatIsValid(false)
            }else{
                setVatresponse('') 
                setVatIsValid(true)
            }
        }



    }

    const changeFldValue=(e,key)=>{

       if (key=='Country'){
            setCustomer({...customer,Country:e.target.value,VAT:''})
       }else{
            setCustomer({...customer,[key]:e.target.value})
       }     

    }

    const saveContact=(e)=>{
        e.preventDefault()

        const contactObj={
            customer:customer,
            token:process.env.REACT_APP_API_ACCESS_TOKEN
        }
        //console.log(customer)
        axios.post(process.env.REACT_APP_API_SERVER+'customerSave',contactObj,{
        headers: {
            'Content-Type': 'application/json'
        }
        }) 
        .then(res=>{
            if (res.data==1){
                setSaveRes(1)
            }else{
                setSaveRes(2)
            }
            setTimeout(() => {
                setSaveRes(0)     
            }, 3000);            
        })

    }




    return (
        <>

        <Alert variant="success">
            Purchase as a <b>Company</b>
        </Alert>


        <Form id="frm" onSubmit={saveContact}>
            <Table hover striped>
                <tr>

                    <td>
                        Company Name (*)
                    </td>

                    <td>
                        <Form.Control type="text" required value={customer.CompanyName}  onChange={(e)=>changeFldValue(e,'CompanyName')}/>
                    </td>

                </tr>

                <tr>
                    <td>
                        Email (*)
                    </td>
                    <td>
                        <Form.Control readOnly type="email" value={customer.Email} />
                    </td>
                </tr>

                <tr>
                    <td>
                        Country (*)
                    </td>
                    <td>
                        <Form.Control as="select" required onChange={selCountry} value={customer.Country}>
                            <option value="">[* Choose Country]</option>
                            {countriesList}
                        </Form.Control>

                    </td>
                </tr>

                <tr>
                    <td>
                    Registration/Identification No (*)
                    </td>
                    <td>
                        <Form.Control type="text"  required  onChange={chVatnum} ref={VATRef} value={customer.VAT}/>
                        <font size="2">
                        Input only if you purchase as a company. DO NOT USE country's prefix code for VAT numbers, e.g. UK.
                        </font>                         
                    </td>
                </tr>
                {vatresponse.length>0 &&
                <tr>
                    <td colSpan="2">
                    <><br/><Alert variant="warning">
                    <div dangerouslySetInnerHTML={{ __html:vatresponse}}/>
                       {/* {vatresponse} */}
                    </Alert></>
                    </td>
                </tr>
                }


                <tr>
                    <td>
                        First Name (*)
                    </td>
                    <td>
                        <Form.Control type="text" required value={customer.CustomerName} onChange={(e)=>changeFldValue(e,'CustomerName')}/>
                    </td>
                </tr>

                <tr>
                    <td>
                        Last Name (*)
                    </td>
                    <td>
                        <Form.Control type="text" required value={customer.CustomerLastName} onChange={(e)=>changeFldValue(e,'CustomerLastName')}/>
                    </td>
                </tr>

                <tr>
                    <td>
                    Address (*)
                    </td>
                    <td>
                        <Form.Control type="text" required value={customer.Address} onChange={(e)=>changeFldValue(e,'Address')}/>
                    </td>
                </tr>

                <tr>
                    <td>
                    Address 2
                    </td>
                    <td>
                        <Form.Control type="text" placeholder="Any additional details, as apartment, building etc..." value={customer.AddressExtraInfo} onChange={(e)=>changeFldValue(e,'AddressExtraInfo')}/>
                    </td>
                </tr>
                <tr>
                    <td>
                    City/Town (*)
                    </td>
                    <td>
                        <Form.Control type="text" required value={customer.City} onChange={(e)=>changeFldValue(e,'City')}/>
                    </td>
                </tr>

                <tr>
                    <td>
                    Zip/Postal Code (*)
                    </td>
                    <td>
                        <Form.Control type="text" required value={customer.Zip} onChange={(e)=>changeFldValue(e,'Zip')}/>
                    </td>
                </tr>

                <tr>
                    <td>
                    State/County (*)
                    </td>
                    <td>
                        <Form.Control type="text" required value={customer.State} onChange={(e)=>changeFldValue(e,'State')}/>
                    </td>
                </tr>

                <tr>
                    <td>
                    Telephone
                    </td>
                    <td>
                        <Form.Control required type="text" required value={customer.Telephone} onChange={(e)=>changeFldValue(e,'Telephone')}/>
                    </td>
                </tr>


                <tr><td height="10"></td></tr>
                <tr>
                    <td colspan="2" align="center">
                    {saveRes==1 &&
                        <Alert variant="success">
                            Success!
                        </Alert>                    
                    }
                    {saveRes==2 &&
                        <Alert variant="danger">
                            An error has occured. Please try again.
                        </Alert>                    
                    }

                    </td>
                </tr>


                <tr>
                    <td colspan="2" align="center">
                    <Button disabled={!vatIsValid} variant="primary" type="submit" form="frm" style={{width:"300px"}}>Save</Button>
                    </td>
                </tr>



            </Table>
            </Form>
       







        </>
    )
}
