import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import { useParams } from 'react-router-dom'
import {Nav,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import { FaListAlt,FaDownload,FaCreditCard,FaFileInvoice,FaInfoCircle, FaChessQueen } from 'react-icons/fa'
import LicencesActive from './LicencesActive'



export default function LicenceDetails(props) {
    const params = useParams();

    const {customername,setCustomername}=useContext(UserContext)
    const {isAuth,usertoken,setUsertoken}=useContext(UserContext)

    const [allLicences,setAllLicences]=useState([])
    const [numOfAllLicences,setNumOfAllLicences]=useState(0)

    const [licences,setLicences]=useState([])
    const [numOfActiveLicences,setNumOfActiveLicences]=useState(0)
    
    const [pendinglicences,setPendingLicences]=useState([])
    const [numOfPendingLicences,setNumOfPendingLicences]=useState(0)

    const [expiringlicences,setExpiringLicences]=useState([])
    const [numOfExpiringLicences,setNumOfExpiringLicences]=useState(0)

    const [expiredlicences,setExpiredLicences]=useState([])
    const [numOfExpiredLicences,setNumOfExpiredLicences]=useState(0)

    const [isLoading,setIsLoading]=useState(true)  

    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState( 'active');
   


    useEffect(()=>{ 
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        formData.append('licenceIsActive',1)
        formData.append('OrderStatus',1)
        axios.post(process.env.REACT_APP_API_SERVER+'licences',formData,{}) 
        .then(res=>{  
            const allLicences=res.data
            const aLicences=res.data.filter(item=>item.OrderStatus==1 && item.DaysLeft>0)
            const pLicences=res.data.filter(item=>item.OrderStatus==0 && item.PaymentMethod==3)
            const eLicences=res.data.filter(item=>item.OrderStatus==1 && (item.DaysLeft<30 && item.DaysLeft>-1) && (item.ItemCode==8))
            const eeLicences=res.data.filter(item=>item.OrderStatus==1 && item.DaysLeft<0 && (item.ItemCode==8))

            setAllLicences(allLicences)
            setNumOfAllLicences(allLicences.length)

            setLicences(aLicences)
            setNumOfActiveLicences(aLicences.length)
            
            setPendingLicences(pLicences)
            setNumOfPendingLicences(pLicences.length)
            
            setExpiringLicences(eLicences)
            setNumOfExpiringLicences(eLicences.length)
            
            setExpiredLicences(eeLicences)
            setNumOfExpiredLicences(eeLicences.length)


            if (eLicences.length>0 || eeLicences.length>0){
                setShow(true)
            }

            setIsLoading(false)



        }) 
    
    },[usertoken])   

    if (isLoading){
        return(
            <>
            <div className="container" style={{minHeight:"110vh"}}>

            <br/>
            <Alert  variant="warning">
                Loading...Please Wait
            </Alert>
            </div>
            </>
    
        )
    }else{

        return (
        <div className="container" >
        <br/>
        {show &&
        <>    
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Warning!</Alert.Heading>
        <p>
            There are expiring (active for less than a month) or expired licences.
            Please click on the <b>Expiring Licences</b> or <b>Expired Licences</b> tab to renew.
        </p>
      </Alert>

      </>}




        <Card className="text-center">
        <Card.Header>
        Licences 
        </Card.Header>
        <Card.Body>
            
            <Tabs defaultActiveKey={params.c=='pending' ? 'pending' : 'active'} id="uncontrolled-tab-example">
            <br/>

                {/* <Tab eventKey="all" title={"All Licences ("+numOfAllLicences+")"}>
                    <LicencesActive licences={allLicences} details="0" renew="0" key={numOfAllLicences}/>
                </Tab>             */}
                <Tab eventKey="active" title={"All Active Licences ("+numOfActiveLicences+")"}>
                    <LicencesActive licences={licences} details="1" renew="0" key={numOfActiveLicences}/>
                </Tab>
                <Tab eventKey="pending" title={"Pending Licence Activations ("+numOfPendingLicences+")"}>
                    <LicencesActive key={numOfPendingLicences} numOfPendingLicences={numOfPendingLicences} setNumOfPendingLicences={setNumOfPendingLicences} licences={pendinglicences} details="2" renew="0"/>
                </Tab>
                <Tab eventKey="expiring" title={"Expiring Licences ("+numOfExpiringLicences+")"}>
                    <LicencesActive key={numOfExpiringLicences} licences={expiringlicences} details="0" renew="1"/>
                </Tab>

                <Tab eventKey="expired" title={"Expired Licences ("+numOfExpiredLicences+")"}>
                <LicencesActive key={numOfExpiredLicences} licences={expiredlicences} details="0" renew="1"/>
                </Tab>
            </Tabs>                                             
        </Card.Body>
        <Card.Footer className="text-muted">
        </Card.Footer>
        </Card>


        </div>

        )
    }

}
