import React ,{useContext,useState,useEffect} from 'react'
import {Nav,Alert,Card,Button,Form,Table,ToggleButton} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import BuyNav from './BuyNav'
import {UserContext} from './UserContext'
import { FaListAlt,FaDownload,FaCreditCard,FaFileInvoice,FaInfoCircle,FaPaypal,FaUniversity } from 'react-icons/fa'

export default function BuyStep3() {
  const {items,setItems,setCurrentstep,order,setOrder,confirmmydata,setConfirmmydata,customer,setCustomer}=useContext(OrderContext)
  const {usertoken}=useContext(UserContext)
  const [paymentMethods,setPaymentMethods]=useState([])
  const [paymentMethod,setPaymentMethod]=useState({})
  //const [paymentMethodDescr,setPaymentMethodDescr]=useState([])

  useEffect(()=>{
    const pm=[
        // {
        //   "id":1,
        //   "title":"CREDIT/DEBIT CARD",
        //   "description":"We accept payments via the following credit/debit cards: VISA, MasterCard, Maestro International, Diners, Discover, JCB.",
        //   "icon": <FaCreditCard/>
        // },
        {
          "id":2,
          "title":"PAYPAL",
          "description":"After submitting your order, you will be re-directed to the PayPal website where you can make your payment. Once your payment has successfully been completed and confirmed by PayPal, your Seametrix account activation will be initiated.Alternatively, you may pay directly by credit card. Your credit card data will be transferred securely with TLS encryption.",
          "icon": <FaPaypal />
        },
        {
          "id":3,
          "title":"BANK TRANSFER",
          "description":"At a later step during checkout (when you press “Proceed with payment”), you will be provided with detailed instructions & information about the payment for your Seametrix products by bank remittance.",
          "icon": <FaUniversity />
        }                
    ]

    const pmEmpty=[
      {
        "id":0,
        "title":"",
        "description":"",
        "icon": ""
      }
    ]

    setPaymentMethods(pm)
    if (order.paymentMethodID){
      setPaymentMethod(pm.filter(item=>item.id==order.paymentMethodID)[0]) 
      //console.log(pm.filter(item=>item.id==order.paymentMethodID)[0])
      //setPaymentMethod(pmEmpty)
    }else{
      setPaymentMethod(pmEmpty)
    }
    

  },[])

  const chPM=(id)=>{
    setPaymentMethod(paymentMethods.filter(item=>item.id==id)[0]) 
    setOrder({...order,paymentMethodID:id})
    
    //console.log(order)
  }

  const btnStyle={
      width: "150px"
  }    
    
  const pmList=paymentMethods.map((item,index)=>{
      return(
        <>

        <Button variant={item.id==paymentMethod.id ? 'primary' : 'light'} style={{width:"400px"}} onClick={()=>chPM(item.id)}>
          <h4>
            {item.icon}
            &nbsp;
            {item.title}
          </h4>
        
        </Button>

        <br/><br/>

        </>
      )
      
  })






    return (
        <>
        <Card className="text-center">
        <Card.Header>
          <BuyNav/>
        </Card.Header>
        <Card.Body >
        <Card.Title>Please select the payment method of your choice</Card.Title>
        <br/><br/>
        <div className="row">
          <div className="col-sm">
            {pmList}
          </div>
          <div className="col-sm">
            {order.paymentMethodID ? 
                  <Card>
                  <Card.Header><h4>{paymentMethod.title}</h4></Card.Header>
                  <Card.Body>
                    {paymentMethod.description}
                  </Card.Body>
                </Card>
                : null}
          </div>

        </div>           
            

            
      </Card.Body>
      <Card.Footer className="text-muted">
            <Button onClick={()=>setCurrentstep(2)}  variant="secondary" style={btnStyle}>Previous</Button>
            &nbsp;
            <Button onClick={()=>setCurrentstep(4)} variant="primary" style={btnStyle} disabled={!order.paymentMethodID}>Next</Button>
        </Card.Footer>
    </Card>
    </>
    )

}
