import React, {useState,useContext,createContext,useEffect}  from 'react'
import {BrowserRouter as Router, Switch,Route,HashRouter} from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import {UserContext} from './UserContext'
import LicenceDetails from './LicenceDetails'
import Buy from './Buy'
import Renew from './Renew'
import Footer from './Footer'
import Invoices from './Invoices'
import Download from './Download'
import Support from './Support'
import BillingDetails from './BillingDetails'
import Account from './Account'
import Order from './Order'
import Register from './Register'
import Login from './Login'
import Dashboard from './Dashboard'
import PasswordRecover from './PasswordRecover'
import {Redirect,useHistory,Link} from 'react-router-dom'




export default function App() {
  const [isAuth,setIsAuth]=useState(false)
  const [usertoken,setUsertoken]=useState(null)
  const [customername,setCustomername]=useState(null)
  const history=useHistory()

  useEffect(() => {


    // alert(1)
    window.addEventListener("beforeunload", ()=>{});
    // return () => {
    //   window.removeEventListener("beforeunload", alertUser);
    // };


  },[])

  // useEffect(() => {
  //   setUsertoken(localStorage.getItem("userToken"))
  //   setCustomername(localStorage.getItem("Customername"))  
  // }, [usertoken])


  return (

    <UserContext.Provider value={{isAuth,setIsAuth,usertoken,setUsertoken,customername,setCustomername}}>
      <HashRouter basename={'/v2'}>
        
     

        <Route exact path="/" component={Register} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/passwordrecover" component={PasswordRecover} />

        <Route
            exact
            path='/register/login'
            render={(props) => (
              <Login {...props} justRegistered={true} />
            )}
        />       
        
        <ProtectedRoute exact path="/dashboard/licencedetails" component={LicenceDetails} />
        <ProtectedRoute exact path="/dashboard/licencedetails/:c" component={LicenceDetails} />
        <ProtectedRoute exact path="/dashboard/invoices" component={Invoices}/>
        <ProtectedRoute exact path="/dashboard/buy" component={Buy} />
        <ProtectedRoute exact path="/dashboard/renew" component={Renew} />
        <ProtectedRoute exact path="/dashboard/download" component={Download} />
        <ProtectedRoute exact path="/dashboard/support" component={Support} />
        <ProtectedRoute exact path="/dashboard/billingdetails" component={BillingDetails} />
        <ProtectedRoute exact path="/dashboard/account" component={Account} />
        <ProtectedRoute exact path="/dashboard/order/:id" component={Order} />
        <ProtectedRoute exact path="/dashboard" component={LicenceDetails} />
        <Footer/>

      </HashRouter> 
  </UserContext.Provider>



  )
}

