import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {Accordion,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import seametrix_chat from '../seametrix_chat.png'
import Seametrix_email from '../Seametrix_email.png'
import Seametrix_switchboard from '../Seametrix_switchboard.png'


export default function Support() {
    const [customer,setCustomer]=useState({})
    const {usertoken}=useContext(UserContext)
    const [message,setMessage]=useState('')
    const [isLoading,setIsLoading]=useState(true)
    const [mailSend,setMailSend]=useState(false)
    const [mailSendErr,setMailSendErr]=useState(false)

    useEffect(() => {


        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        axios.post(process.env.REACT_APP_API_SERVER+'customer',formData,{}) 
        .then(res=>{  
            setCustomer(res.data)
            setIsLoading(false)  
        })
        
    },[])

    const sendMessage=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        formData.append('message',message)
        axios.post(process.env.REACT_APP_API_SERVER+'sendMailFromDashboardSupport',formData,{}) 
        .then(res=>{

            if (res.data==0){
                setMessage('')
                setMailSend(true)
                setMailSendErr(false)
                // setInterval(() => {
                //     setMailSend(false)
                // }, 5000);
            }else{
                setMailSendErr(true)
                setMailSend(true)
            }


        }).catch(err=>{
            setMailSendErr(true)
            setMailSend(true)
        })
    }

    const theMessage=(e)=>{
        setMessage(e.target.value)
        if (mailSend){
            setMailSend(false)
            setMailSendErr(false)
        }
        
    }

    if (isLoading){
       return(
        <div className="container" style={{minHeight:"110vh"}}><br/>
            <Alert  variant="warning">Loading...Please Wait</Alert>
        </div>
        )
        
    }else{
    return (
        <div className="container">
        <br/>

        <Card className="text-center">
        <Card.Header>
        Contact & Support
        </Card.Header>
        <Card.Body>

            <table width="100%">
                <tr>
                    <td width="45%" valign="top" align="left">
                    <div class="row m-b-20">
                            <div className="col-sm-12">
                                <h3 className="title">Send us a Message</h3>
                                <p className="text-muted sub-title">We're here to answer any questions/queries you may have about Seametrix.<br/><br/> Reach out to us, and we'll respond as soon as we can! </p>
                            </div>
                        </div>
                        <form onSubmit={sendMessage}>
                        <div className="mb-3">
                        <input readOnly value={customer.CustomerName+' '+customer.CustomerLastName} type="text" className="form-control" />
                        </div>                    
                        <div className="mb-3">
                        <input readOnly value={customer.Email} type="email" className="form-control" />
                        </div>
                        <div className="mb-3">
                        <label for="exampleFormControlTextarea1" className="form-label">Your message</label>
                        <textarea 
                            required 
                            className="form-control"  
                            rows="3"
                            value={message}
                            onChange={theMessage}    
                        ></textarea>
                        </div>
                        <div className="mb-3">
                        <button type="submit" class="btn btn-info mb-3">Send Your Message</button>
                        </div>
                        </form>

                        {mailSend &&
                           <Alert  variant={mailSendErr ? 'danger' : 'success'}>{mailSendErr ? 'An error has occured, please try again.' : 'Mail Sent!'}</Alert>
                        
                        }
                    </td>
                    <td width="10%"></td>
                    <td width="45%" align="left" valign="top">


                    <table>
                        <tr>
                            <td valign="top">
                                <img src={Seametrix_switchboard} width="50"/>
                            </td>
                            <td width="10"></td>
                            <td valign="top">
                                <font face="tahoma" size="4" color="#09b7da"><b>CALL US</b></font>
                                <br/><br/>
                                <font face="tahoma" size="3">+30 211 7809999</font>
                                <br/>        
                            </td>
                        </tr>
                        <tr><td height="40"></td></tr>

                        <tr>
                            <td valign="top">
                                <img src={seametrix_chat} width="50"/>
                            </td>
                            <td width="10"></td>
                            <td valign="top">
                                <font face="tahoma" size="4" color="#09b7da"><b>LIVE CUSTOMER SUPPORT</b></font>
                                <br/><br/>
                                <font face="tahoma" size="3">Monday to Friday 09.00′- 17.00′ EET</font>
                                <br/>        
                            </td>
                        </tr>
                        <tr><td height="40"></td></tr>


                        <tr>
                            <td valign="top">
                                <img src={Seametrix_email} width="50"/>
                            </td>
                            <td width="10"></td>
                            <td valign="top">
                                <font face="tahoma" size="4" color="#09b7da"><b>EMAIL US</b></font>
                                <br/><br/>
                                <font face="tahoma" size="3">Please fill in the message form on the left, and we will get back to you the soonest possible.</font>
                                <br/>        
                            </td>
                        </tr>
                        <tr><td height="40"></td></tr>
                    </table>
                    </td>
                </tr>                       
            </table>


        </Card.Body>
        <Card.Footer className="text-muted">
        </Card.Footer>
        </Card>

        </div>
    )
    }
}
