import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {Accordion,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import InvoiceDets from './InvoiceDets'
import { FaUniversity,FaPaypal,FaCreditCard,FaFileInvoice,FaInfoCircle, FaChessQueen } from 'react-icons/fa'



export default function Invoices() {
    const [isLoading,setIsLoading]=useState(true)  
    const {customername}=useContext(UserContext)
    const {isAuth,usertoken}=useContext(UserContext)
    const [invoices,setInvoices]=useState([])

    useEffect(()=>{
        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)

        axios.post(process.env.REACT_APP_API_SERVER+'invoices',formData,{}) 
        .then(res=>{  
            setInvoices(res.data)
            setIsLoading(false)
        })

    },[invoices])


    const invoicesList=invoices.map((item,index)=>{
        if (item.PaymentMethod==1){
            var pm=<FaCreditCard/>
        } 
        if (item.PaymentMethod==2){
            var pm=<FaPaypal/>
        } 
        if (item.PaymentMethod==3){
            var pm=<FaUniversity/>
        } 
         
        return(
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={index+1}>
                                        
                 {pm}&nbsp;&nbsp;<b>Order Key:</b> {item.Timestamp} &nbsp;|&nbsp; <b>Order Date:</b> {item.ODate} &nbsp;|&nbsp; {item.OrderType}
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index+1}>
                <Card.Body>
                    <InvoiceDets otoken={item.otoken} invLink={item.InvoiceLink} invoices={invoices}/>
                </Card.Body>
                </Accordion.Collapse>
            </Card>            
        )
    })

    if (isLoading){
        return(
            <>
            <div className="container">

            <br/>
            <Alert  variant="warning">
                Loading...Please Wait
            </Alert>
            </div>
            </>
    
        )
    }else{


        return (
            <div className="container">
            <br/>

            <Card className="text-center">
            <Card.Header>
            Invoices
            </Card.Header>
            <Card.Body>

            <Accordion>
                {invoicesList}

            </Accordion>



                                                
            </Card.Body>
            <Card.Footer className="text-muted">
            </Card.Footer>
            </Card>

            </div>
        )
    }
}
