import React ,{useContext,useState,useEffect} from 'react'
import {UserContext} from './UserContext'
import {CustomerContext} from './CustomerContext'
import {Accordion,Alert,Card,Button,Form,Table,Tab,Tabs} from "react-bootstrap"
import axios from 'axios'
import { FaUniversity,FaPaypal,FaCreditCard,FaFileInvoice,FaInfoCircle, FaChessQueen } from 'react-icons/fa'
import BillingDetailsCompany from './BillingDetailsCompany'
import BillingDetailsIndividual from './BillingDetailsIndividual'

export default function BillingDetails() {
    const [isLoading,setIsLoading]=useState(true)  
    const {customername}=useContext(UserContext)
    const {isAuth,usertoken}=useContext(UserContext)
    const [customer,setCustomer]=useState({})

    useEffect(() => {


        const formData=new FormData()
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        formData.append('customertoken',usertoken)
        axios.post(process.env.REACT_APP_API_SERVER+'customer',formData,{}) 
        .then(res=>{ 
            //console.log(res.data) 
            setCustomer(res.data)
            setIsLoading(false)  
        })
        
    },[usertoken])

    const setCustomerType=(ct)=>{
        setCustomer({...customer,'CustomerType':ct})
    }

    const sbFrm=(e)=>{
        e.preventDefault()
    }

    if (isLoading){
        return(
         <div className="container"><br/>
             <Alert  variant="warning">Loading...Please Wait</Alert>
         </div>
         )
         
     }else{
        return (
            <div className="container">
            <br/>

            <Card className="text-center">
            <Card.Header>
            Billing Details
            </Card.Header>
            <Card.Body>

            <Button variant={customer.CustomerType==1 ? "success" :"secondary"} onClick={()=>setCustomerType(1)}>Purchase as Company</Button>
            &nbsp;&nbsp;
            <Button variant={customer.CustomerType==2 ? "success" :"secondary"} onClick={()=>setCustomerType(2)}>Purchase as Individual</Button>
            <hr/>
            
            <CustomerContext.Provider value={{customer,setCustomer}}>
      
                {customer.CustomerType==1  && <BillingDetailsCompany />}
                {customer.CustomerType==2  && <BillingDetailsIndividual/>}
            </CustomerContext.Provider>  
           


            </Card.Body>
            <Card.Footer className="text-muted">
            
            </Card.Footer>
            </Card>

            </div>        
        )
    }
}
