import React ,{useContext,useState,useEffect} from 'react'
import {Nav,Alert,Card,Button,Form,Table,Modal} from "react-bootstrap"
import axios from 'axios'
import {OrderContext} from './OrderContext'
import BuyNav from './BuyNav'
import {UserContext} from './UserContext'
import {Link} from 'react-router-dom'
import { FaListAlt,FaDownload,FaCreditCard,FaFileInvoice,FaInfoCircle,FaPaypal,FaUniversity } from 'react-icons/fa'
import RegTerms from './RegTerms'

export default function BuyStep4() {
  const {items,setItems,setCurrentstep,order,setOrder,confirmmydata,setConfirmmydata,customer,setCustomer}=useContext(OrderContext)
  const {usertoken}=useContext(UserContext)
  const [pm,setPm]=useState(null)
  const [bTitle,setBTitle]=useState('')
  const [iAgree,setIAgree]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const [show, setShow] = useState(false)
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 

  useEffect(()=>{
    
    const vatValuePerCent=order.vatValuePerCent
    const vatValue=parseFloat(parseFloat(order.gtotal)*(parseFloat(vatValuePerCent)/100)).toFixed(2)
    const finalCost=parseFloat(parseFloat(order.gtotal)+parseFloat(vatValue)).toFixed(2)
    setOrder({...order,'vatValue':vatValue,'finalCost':finalCost})
    
    if (order.paymentMethodID==1){
      setPm(<FaCreditCard/>)
    }

    if (order.paymentMethodID==2){
      setPm(<FaPaypal />)
    }    

    if (order.paymentMethodID==3){
      setPm(<FaUniversity />)
    }

    if (customer.CustomerType==1){
      setBTitle("Purchase as Company")
    }else{
      setBTitle("Purchase as Individual")
    }

  },[])


  const agree=(e)=>{
    setIAgree(e.target.checked)
  }

  const checkOut=()=>{
      
      setIsLoading(true)
      const orderObj={
        order:order,
        customer:customer,
        token:process.env.REACT_APP_API_ACCESS_TOKEN
      }

      axios.post(process.env.REACT_APP_API_SERVER+'placeorder',orderObj,{
        headers: {
          'Content-Type': 'application/json'
        }
      }) 
      .then(res=>{
       console.log(res.data)
        if (res.data.err==0){
          setOrder({...order,otoken:res.data.order})
          setCurrentstep(5)
          setIsLoading(false)
        }else{
          alert("An Error Has Occured. Please Try Again.")
          setIsLoading(false)

        }
      })
    }


    const btnStyle={
        width: "150px"
    }    
    
    const orderItems=order.items.map(item=>{
      return (
        
        <tr>
          <td>
          <img src={require('../'+item.code+'.png').default}/>            
          </td>
          <td>
            {item.title}
          </td>
          <td>
            {item.licences}
          </td>

          <td>
           {item.years}
          </td>

          <td align="right">
            {parseFloat(item.price).toFixed(2)}
          </td>

          <td align="right">
            {parseFloat(item.subtotal).toFixed(2)}
          </td>

        </tr>

      )
    })


    return (
        <>
        <Card className="text-center">
        <Card.Header>
          <BuyNav/>
        </Card.Header>
        <Card.Body>
        <Card.Title>Preview Your Order</Card.Title>
          
        <Card>
        <Card.Header className="card-header text-white" style={{backgroundColor: "#343a40"}}>Quantity, Time and Cost</Card.Header>
        <Card.Body>
        <Table  bordered hover>
            <thead>
              <tr bgColor="#f0f0f0">
                <th></th>
                <th>Title</th>
                <th>Licences</th>
                <th>Years</th>
                <th>Unit Price &euro;</th>
                <th>Total &euro;</th>
              </tr>
            </thead>
            <tbody>
              {orderItems}
              <tr><td colspan="6"></td></tr>
              <tr bgColor="#f0f0f0">
              <td colspan="4">
                {order.discount>0 ? 'Coupon Discount: '+order.discount+'%' + ' ,Coupon Num:'+order.coupon: ''}

              </td>
                <td valign="top" align="right"><b>NET TOTAL</b></td>
                
                <td align="right" valign="top">

                    {parseFloat(order.gtotal).toFixed(2)}&nbsp;&euro;

                </td>
              </tr>
              <tr><td colspan="6"></td></tr>
              <tr bgColor="#f0f0f0">
              <td colspan="4"></td>
                <td valign="top" align="right"><b>VAT {order.vatValuePerCent}%</b></td>
                
                <td align="right" valign="top">

                    {parseFloat(order.vatValue).toFixed(2)}&nbsp;&euro;

                </td>
              </tr>
              <tr><td colspan="6"></td></tr>
              <tr bgColor="#bde0c9">
              <td colspan="4">
                


              </td>
                <td valign="top" align="right"><h5>FINAL PRICE</h5></td>
                
                <td align="right" valign="top">

                   <h5>{pm} &nbsp; {parseFloat(order.finalCost).toFixed(2)}&nbsp;&euro;</h5> 

                </td>
              </tr>


            </tbody>

        </Table>



        </Card.Body>
      </Card>          
      <br/><br/>
      <Card>
        <Card.Header className="card-header text-white" style={{backgroundColor: "#343a40"}}>Billing Details - {bTitle}</Card.Header>
        <Card.Body>
            <Table striped bordered hover width="80%">
                <tbody>
                {customer.CustomerType==1 ?
                  <>
                  <tr align="left">
                    <td width="30%"><b>Company Name</b></td>
                    <td>{customer.CompanyName}</td>
                </tr>
                <tr align="left">
                    <td width="30%"><b>Registration/Identification No</b></td>
                    <td>{customer.VAT}</td>
                </tr>                  
                </>
                : null}
                
                
                <tr align="left">
                    <td width="30%"><b>Name</b></td>
                    <td>{customer.CustomerName}</td>
                </tr>
                <tr align="left">
                    <td width="30%"><b>Last Name</b></td>
                    <td>{customer.CustomerLastName}</td>
                </tr>
                <tr align="left">
                    <td width="30%"><b>Email</b></td>
                    <td>{customer.Email}</td>
                </tr>

                <tr align="left">
                    <td width="30%"><b>Country</b></td>
                    <td>{customer.Country}</td>
                </tr>

                <tr align="left">
                    <td width="30%"><b>Address</b></td>
                    <td>{customer.Address}</td>
                </tr>

                <tr align="left">
                    <td width="30%"><b>Address2</b></td>
                    <td>{customer.AddressExtraInfo}</td>
                </tr>

                <tr align="left">
                    <td width="30%"><b>City/Town</b></td>
                    <td>{customer.City}</td>
                </tr>
                <tr align="left">
                    <td width="30%"><b>Zip/Postal Code</b></td>
                    <td>{customer.Zip}</td>
                </tr>
                <tr align="left">
                    <td width="30%"><b>Telephone </b></td>
                    <td>{customer.Telephone }</td>
                </tr>
                               
                </tbody>

            </Table>
        </Card.Body>
      </Card>  

      <br/>
      <table>
      <tr>
          <td>
          <Form.Check checked={iAgree} value={iAgree} onChange={agree} type="checkbox" label="I agree with the " style={{color:"#00acc1",fontWeight:"bold"}}/>
          </td>
          <td>
          <Link onClick={handleShow}><span style={{color:"#00acc1",fontWeight:"bold"}}>Terms and Conditions</span></Link>
          </td>
      </tr>
  </table>

      

      {isLoading &&  <><hr/><Alert  variant="warning">Processing Your Order...Please Wait</Alert><hr/></>}
      </Card.Body>
      {!isLoading &&
      <Card.Footer className="text-muted">
            <Button onClick={()=>setCurrentstep(3)} variant="secondary" style={btnStyle}>Previous</Button>
            &nbsp;
            <Button onClick={checkOut}  variant="primary" style={btnStyle} disabled={!iAgree}>Next</Button>
        </Card.Footer>
      }
    </Card>
    
    
    <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>Terms And Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <RegTerms/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="info" onClick={handleClose}>
            I Accept
            </Button>
            </Modal.Footer>
            </Modal>  
    
    
    
    
    
    </>




)
}
