import React ,{useContext,useState,useEffect} from 'react'
import {OrderContext} from './OrderContext'
import {UserContext} from './UserContext'
import BuyStep1 from './BuyStep1'
import BuyStep11 from './BuyStep11'
import BuyStep2 from './BuyStep2'
import BuyStep3 from './BuyStep3'
import BuyStep4 from './BuyStep4'
import BuyStep5 from './BuyStep5'
import axios from 'axios'
import { useLocation } from "react-router-dom";

export default function Buy() {
  const [customer,setCustomer]=useState(null)
  const [order,setOrder]=useState({})
  const [items,setItems]=useState([])
  const [currentstep,setCurrentstep]=useState(1)
  const [confirmmydata,setConfirmmydata]=useState(false)
  const {usertoken}=useContext(UserContext)
  const location = useLocation()
  const [renewItems,setRenewItems]=useState([])


    useEffect(() => {
      const formData=new FormData()
      formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
      formData.append('customertoken',usertoken)
      axios.post(process.env.REACT_APP_API_SERVER+'customer',formData,{}) 
      .then(res=>{  
          setCustomer(res.data)  
      })
    },[])


    return (
      <>
   
      <div className="container" >
      <br/>
      <OrderContext.Provider value={{items,setItems,setCurrentstep,setOrder,order,currentstep,customer,setCustomer,confirmmydata,setConfirmmydata,renewItems}}>
      

        {currentstep===1 && <BuyStep1 />}
        {currentstep===2 && <BuyStep2 />}
        {currentstep===3 && <BuyStep3 />}
        {currentstep===4 && <BuyStep4 />}
        {currentstep===5 && <BuyStep5 />}
      </OrderContext.Provider>
      </div>  
      </>


    )
}
