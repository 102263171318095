import React, {useRef,useState,useEffect,useContext} from 'react'
import {Form,Card,Button,Alert} from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
import {Container} from 'react-bootstrap'
import {UserContext} from './UserContext'
import {Redirect,useHistory,Link} from 'react-router-dom'
import smtrxLogo from '../smtrxLogo.png'
import PasswordRecover from './PasswordRecover'

export default function (props) {
    const Email=useRef()
    const PWD=useRef()
    const recaptchaRef=useRef()

    const history=useHistory()

    const [error,setError]=useState(false)
    const [success,setSuccess]=useState(false)
    const [emsg,setEmsg]=useState('')
    const [btndsb,setBtndsb]=useState(true)
    const [isLoading,setIsLoading]=useState(false)
    const [pwdrec,setPwdrec]=useState(false)

    const {setUsertoken}=useContext(UserContext)
    const {setIsAuth}=useContext(UserContext)
    const {setCustomername}=useContext(UserContext)
    const {isAuth}=useContext(UserContext)

    const login=(e)=>{
        e.preventDefault()
        setIsLoading(true)
        setError(false)
        setSuccess(false)

        const formData=new FormData()
        formData.append('Email',Email.current.value)
        formData.append('PWD',PWD.current.value)
        formData.append('token',process.env.REACT_APP_API_ACCESS_TOKEN)
        axios.post(process.env.REACT_APP_API_SERVER+'login',formData,{}) 
        .then(res=>{
            if (res.data.Token){
                setIsLoading(false)
                setSuccess(true)
                setBtndsb(true)
                setEmsg("LOGIN OK")
                setIsAuth(true)
                localStorage.setItem("Auth", 1)
                localStorage.setItem("userToken", res.data.Token)
                localStorage.setItem("Customername", res.data.CustomerName)
               // localStorage.setItem("CompanyName", res.data.CompanyName)
                setUsertoken(res.data.Token)
                setCustomername(res.data.CustomerName)
                history.push('/dashboard/licencedetails')
            }else{
                localStorage.setItem("Auth", 0)  
                setIsLoading(false)             
                setError(true)
                setEmsg("LOGIN FAILED")
                Email.current.value=''
                PWD.current.value=''
                setBtndsb(true)
                recaptchaRef.current.reset() 
            }
        }).catch(err=>{
            setError(true)
            setEmsg("ERROR: An error has occured. Please try again.")
            setBtndsb(true)
            recaptchaRef.current.reset()  

        })   
    }


    function onChange(value) {
        if (value){
            setBtndsb(false)
        }
    }

    function onExpired(){
        setBtndsb(true)
    }    

    return (
        <>
            <Container className="d-flex  justify-content-center" style={{minHeight:"100vh"}}>
            <div className="w-100" style={{maxWidth:"500px",paddingTop: "50px"}}>
            <Card>
            <a href="https://seametrix.net">
            <Card.Img variant="top" src={smtrxLogo} style={{backgroundColor:"#676766"}}/>
            </a>
                <Card.Body>
                {!pwdrec &&
                    <Form onSubmit={login}>

                        {props.justRegistered &&
                            <Alert variant="success">
                                <font color="#F36257" size="3">
                                Please check your email in order to activate your account.
                                <br/><br/>
                                *If you don't see our verification email, please check your Spam/Junk folder
                                <br/><br/>
                                </font>
                                 <font color="#09b7da" size="3">
								To activate your account click on the verification link. Once done you will receive your
                                trial credentials as well as the download link and instructions, in order to use Seametix software.
                                </font>                          
                            </Alert>
                        
                        }
                        
                        <Form.Group>
                            <Form.Control  autoComplete="xx@joijfoer.com" type="email" required ref={Email} placeholder="Your Email"></Form.Control>
                        </Form.Group>


                        <Form.Group>
                            <Form.Control maxLength="12" autoComplete="off" type="password" required ref={PWD} placeholder="Password"></Form.Control>
                        </Form.Group>

                        {error && <Alert variant="danger">{emsg}</Alert>}
                        {success && <Alert variant="success">{emsg}</Alert>}
                        {isLoading && <Alert variant="success">Please Wait...</Alert>}
                        <p align="center">
                        <hr/>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_CAPTACHA_SITE_KEY}
                            onChange={onChange}
                            onExpired={onExpired}
                        />
                        <hr/>

                        <Button disabled={btndsb} type="submit" className="w-40" style={{backgroundColor:"#f5707a",borderStyle:"none","width":"200px"}}>Log In</Button>  
                        <br/><br/>
                        <p className="text-muted">
                      
                        Don't have an account? <Link to='/register'><b><font color="#09B7DA">Register</font></b></Link>
                        <br/>
                        OR
                        <br/>
                        <a href="https://seametrix.net/"><b><font color="#09B7DA">Return to Homepage</font></b></a>
                        
                        </p>                        
                        
                        </p>
                        <p className="text-muted" style={{textAlign:"center"}}><i>
                            <Link onClick={()=>setPwdrec(true)}><font color="gray">Forgot Your password?</font></Link>
                        </i></p>
                    </Form>
                }

                    {pwdrec &&
                        <PasswordRecover setPwdrec={setPwdrec}/>
                    
                    }    

                </Card.Body>
            </Card>
            </div>
            </Container>
        </>
    )



}
